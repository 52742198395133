import styled from 'styled-components';

interface Props {
  error?: boolean;
}

const InputContainer = styled.div<Props>`
  display: flex;

  input {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  :focus-within {
    input {
      border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
    }

    div > div {
      border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};

      :hover {
        border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
      }
    }
  }

  .select {
    margin-top: ${({ theme: { spaces } }) => spaces.sm};
    min-width: ${({ theme: { sizes } }) => sizes.selectInDounbleInput};

    &__control {
      border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.border)};
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      border-left: none;
    }
  }
`;

const WideContainer = styled.div`
  width: 100%;
`;

export { InputContainer, WideContainer };
