import * as yup from 'yup';
import { object as yupObject } from 'yup';
import { checkEmail, checkName, checkPositiveNumber, checkSurname } from '../yupValidators';

export const addEmployeeSchema = yupObject().shape({
  firstName: checkName(),
  lastName: checkSurname(),
  email: checkEmail(),
  position: yup.object().required('validation.fieldIsRequired'),
  experienceLevel: yup.object().required('validation.fieldIsRequired'),
  role: yup.object().required('validation.fieldIsRequired'),
});

export const addEmployeeSkillsSchema = yupObject().shape({
  level: yup.object().required('validation.fieldIsRequired'),
  skill: yup.object().required('validation.fieldIsRequired'),
});

export const addEmployeeBillingPeriodTypeSchema = yupObject().shape({
  type: yup.object().required('validation.fieldIsRequired'),
});

export const addEmployeeBillingPerHourSchema = yupObject().shape({
  startDate: yup.string().required('validation.fieldIsRequired'),
  endDate: yup.string().required('validation.fieldIsRequired'),
  maxHoursPerDay: checkPositiveNumber(),
  salaryPerHour: checkPositiveNumber(),
  minimumHoursPerMonth: checkPositiveNumber(),
  maximumHoursPerMonth: checkPositiveNumber(),
});

export const addEmployeeBillingPerMonthSchema = yupObject().shape({
  startDate: yup.string().required('validation.fieldIsRequired'),
  endDate: yup.string().required('validation.fieldIsRequired'),
  maxHoursPerDay: checkPositiveNumber(),
  salaryPerMonth: checkPositiveNumber(),
});
