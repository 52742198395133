import styled from 'styled-components';

interface Props {
  size?: string;
  variant?: 'primary' | 'secondary';
}

export const ButtonForIcon = styled.button<Props>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  ${({ theme, variant = 'primary', size }) => {
    switch (variant) {
      case 'primary':
        return `background-color: ${theme.colors.newSuccess};
      border-radius: ${theme.radius.oval};`;
      case 'secondary':
        return `background-color: ${theme.colors.light};
      border-radius: ${theme.radius.sm};
      padding: calc(${size} * (1/2));
      box-sizing: content-box;
      box-shadow: ${theme.colors.boxShadow};
      `;
    }
  }}
`;
