export const spaces = {
  xs: '4px',
  sm: '8px',
  mSm: '12px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '36px',
  xxxl: '48px',
  xsBottomMargin: '0px 0px 8px',
  smBottomMargin: '0px 0px 16px',
  mdBottomMargin: '0px 0px 24px',
  lgBottomMargin: '0px 0px 32px',
  xlBottomMargin: '0px 0px 42px',
  xxlBottomMargin: '0px 0px 48px',
  mdTopMargin: '24px 0px 0px',
  lgTopMargin: '34px 0px 0px',
  mdVerticalMargin: '24px 0px',
  lgVerticalMargin: '32px 0px',
  xlVerticalMargin: '40px 0px',
  defaultPadding: '14px 16px',
  defaultButtonPadding: '8px 32px',
  secondaryButtonPadding: '10px 16px',
  defaultMobileBoxPadding: '40px 16px',
  defaultBoxPadding: '20px',
  defaultFormPadding: '30px',
  largeBoxPadding: '120px',
  defaultImagePadding: '40px 20px',
  defaultIconPadding: '12px 24px',
  pageTabletPadding: '56px 16px 56px 100px',
  pagemobilePadding: '88px 16px',
  pageMobilePadding: '88px 16px',
  modalPadding: '32px 40px',
  mdHorizontalPadding: '0px 16px',
  defaultDisplacement: '14px, -30px',
  desktopDisplacement: '14px, -56px',
  mobileDisplacement: '14px, -52px',
  defaultLabelDisplacement: '14px',
  defaultErrorDisplacement: '-18px',
  defaultTableHeadMargin: '32px 16px 4px',
  defaultSelectDisplacement: '14px, 30px',
  desktopSelectDisplacement: '14px, -8px',
  mobileSelectDisplacement: '14px, -4px',
  desktopTextareaDisplacement: '14px, -108px',
  inputIconTop: '20px',
  inputIconRight: '12px',
  circle: '10px',
  pageMargin: '80px',
};
