import { colors } from './colors';
import { breakPoints } from './breakPoints';
import { radius } from './radius';
import { fontSizes } from './fontSizes';
import { spaces } from './spaces';
import { sizes } from './sizes';

export const theme = {
  colors,
  breakPoints,
  radius,
  fontSizes,
  spaces,
  sizes,
};
