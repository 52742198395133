import { useState, useEffect } from 'react';

import { useApi } from 'hooks/useApi';
import { BasePagination, PagesPossibility } from 'types';
import { prepareURLParamsString } from 'utils/queryParams';
import {
  ProjectClientRes,
  BasePaginationRes,
  ProjectsBudgetDetailsBaseData,
  ReservationWithEmployeeShortcut,
  ProjectFullDataWithoutReservationsAndBudget,
} from 'work-planner-backend/types';

interface UseProjectDetails {
  getProject: (projectId: string) => Promise<void>;
  getBudget: (projectId: string) => Promise<void>;
  getClient: (clientId: string) => Promise<void>;
  getReservations: (projectId: string, pagination?: BasePagination) => Promise<void>;
  reservations: ReservationWithEmployeeShortcut[];
  project?: ProjectFullDataWithoutReservationsAndBudget;
  budget?: ProjectsBudgetDetailsBaseData;
  client?: ProjectClientRes;
  reservationsPagesPosibility?: PagesPossibility;
}

interface Props {
  projectId?: string;
}

export const useProjectDetails = ({ projectId }: Props): UseProjectDetails => {
  const { apiGet } = useApi();
  const [project, setProject] = useState<ProjectFullDataWithoutReservationsAndBudget>();
  const [budget, setBudget] = useState<ProjectsBudgetDetailsBaseData>();
  const [client, setClient] = useState<ProjectClientRes>();
  const [reservations, setReservations] = useState<ReservationWithEmployeeShortcut[]>([]);
  const [reservationsPagesPosibility, setReservationsPagesPosibility] =
    useState<PagesPossibility>();

  const getProject = async (projectId: string) => {
    const result = await apiGet<ProjectFullDataWithoutReservationsAndBudget>({
      url: `projects/${projectId}
    `,
    });

    if (result) {
      setProject(result);
    }
  };

  const getBudget = async (projectId: string) => {
    const result = await apiGet<ProjectsBudgetDetailsBaseData>({
      url: `projects/budget-details/${projectId}
    `,
    });

    if (result) {
      setBudget(result);
    }
  };

  const getClient = async (clientId: string) => {
    const result = await apiGet<ProjectClientRes>({
      url: `projects/clients/agents/${clientId}
    `,
    });

    if (result) {
      setClient(result);
    }
  };

  const getReservations = async (projectId: string, pagination?: BasePagination) => {
    const queryParams = prepareURLParamsString({ ...pagination }, { encode: false });

    const result = await apiGet<BasePaginationRes<ReservationWithEmployeeShortcut> | null>({
      url: `projects/reservations/all/${projectId}/${queryParams}`,
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;

      setReservationsPagesPosibility({ hasNextPage, hasPreviousPage });
      setReservations(items);
    }
  };

  useEffect(() => {
    if (!projectId) return;
    getProject(projectId);
    getBudget(projectId);
    getReservations(projectId);
  }, [projectId]);

  useEffect(() => {
    if (project?.client?.id) {
      getClient(project?.client?.id);
    }
  }, [project?.client?.id]);

  return {
    getProject,
    getBudget,
    getClient,
    getReservations,
    project,
    budget,
    client,
    reservations,
    reservationsPagesPosibility,
  };
};
