import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ColorsInColorPicker } from 'config/theme/basicStyleValues/colors';
import { __ } from 'services/translation';
import { ErrorText } from '../styles';
import {
  CirclePickerBox,
  ColorBox,
  ColorBoxPlaceholder,
  ColorPickerComponent,
  InputLabel,
} from './styles';

interface Props {
  name: string;
  labelText?: string;
  placeholder?: string;
  errorText?: string;
}

export const ColorPicker: FC<Props> = ({ name, labelText, placeholder, errorText }) => {
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [color, setColor] = useState<string>();

  const clickHandle = () => {
    setOpenPicker(!openPicker);
  };
  return (
    <>
      <Controller
        name={name}
        render={({ field: { onChange, value } }) => (
          <ColorPickerComponent onClick={clickHandle} isOpen={openPicker} type="button">
            {value ? (
              <ColorBox color={value.hex} />
            ) : (
              <ColorBoxPlaceholder>{placeholder && __(placeholder)}</ColorBoxPlaceholder>
            )}
            {openPicker && (
              <CirclePickerBox
                onChangeComplete={(color) => setColor(color.hex)}
                onChange={onChange}
                color={value}
                colors={ColorsInColorPicker}
              />
            )}
          </ColorPickerComponent>
        )}
      />
      <InputLabel htmlFor={name} error={Boolean(errorText)} color={color} isOpen={openPicker}>
        {labelText && __(labelText)}
      </InputLabel>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  );
};
