import { FC } from 'react';
import { DatePickerInput, GridItem, Heading, RadioButton } from 'components/common';
import { GridComponent } from 'components/common/Grid/GridContainer/styles';
import { FieldErrorsImpl } from 'react-hook-form';
import { EventType } from 'types';
import { EventNotificationType } from 'work-planner-backend/types';

const notifyRadioGroup = [
  { name: 'notify', value: EventNotificationType.EveryDay, label: 'placeholder.notifyEveryDay' },
  { name: 'notify', value: EventNotificationType.ChosenDay, label: 'placeholder.notifyOnce' },
];

interface Props {
  eventData?: EventType;
  errors?: FieldErrorsImpl<EventType>;
  heading?: string;
}

export const Reminder: FC<Props> = ({ eventData, errors, heading }) => (
  <>
    <Heading text={heading ?? 'heading.reminder'} variant="subheading" margin="mdBottomMargin" />
    <GridComponent size={[2, 3]}>
      <GridItem position={[1, 1]}>
        <DatePickerInput
          name="reminderDate"
          placeholder="placeholder.dateFormat"
          labelText="label.date"
          minDate={eventData ? new Date(eventData.date) : new Date()}
          errorText={errors?.reminderDate?.message}
        />
      </GridItem>
      <GridItem position={[1, 2, 3]}>
        <RadioButton
          radioGroup={notifyRadioGroup}
          prevSelectedValue={eventData ? eventData.notify : EventNotificationType.EveryDay}
        />
      </GridItem>
    </GridComponent>
  </>
);
