import styled from 'styled-components';

interface ListProps {
  gap?: string;
}

export const ListStyled = styled.li<ListProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: no-wrap;
  gap: ${({ theme, gap }) => (gap ? theme.spaces[gap] : theme.spaces.sm)};
`;
