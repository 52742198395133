import styled from 'styled-components';

interface Props {
  alignEnd?: boolean;
}

const Label = styled.label`
  position: absolute;
  z-index: 0;
  padding: 0px ${({ theme }) => theme.spaces.xs};
  left: 0px ${({ theme }) => theme.spaces.defaultLabelDisplacement};
  top: 0px ${({ theme }) => theme.spaces.sm};
  background-color: white;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  max-height: ${({ theme }) => theme.sizes.errorBoxHeight};
  margin-top: ${({ theme }) => theme.spaces.defaultErrorDisplacement};
`;

const HelpText = styled.p<Props>`
  text-align: ${({ alignEnd }) => (alignEnd ? 'end' : 'start')};
  color: ${({ theme }) => theme.colors.border};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  height: ${({ theme }) => theme.sizes.errorBoxHeight};
  margin-top: ${({ theme }) => theme.spaces.defaultErrorDisplacement};
`;

export { Label, ErrorText, HelpText };
