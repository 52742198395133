import { SelectType } from 'types';
import { __ } from 'services/translation';

export const getSelectOptionsFromEnum = <T>(
  object: { [s: string]: T },
  withTranslation = false,
): SelectType[] =>
  Object.values(object).map(
    (option): SelectType => ({
      label: withTranslation ? __(`enum.${option}`.toLowerCase()) : `${option}`,
      value: `${option}`,
    }),
  );
