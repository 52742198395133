import { ButtonHTMLAttributes } from 'react';
import { ButtonType } from 'types';
import { TranslateText } from 'components/common';
import { BasicButton } from '../styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant: ButtonType;
  noTranslate?: boolean;
  positionLeft?: boolean;
}

export const Button = ({
  text,
  variant,
  onClick,
  type,
  noTranslate,
  positionLeft,
  ...rest
}: Props) => (
  <BasicButton
    onClick={onClick}
    variant={variant}
    type={type}
    data-testid={text}
    positionLeft={positionLeft}
    {...rest}
  >
    <TranslateText text={text} noTranslate={noTranslate} />
  </BasicButton>
);
