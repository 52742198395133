export const radius = {
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '20px',
  oval: '50%',
  nav: '0px 20px 20px 0px',
  leftRound: '50% 0px 0px 50%',
  rightRound: '0px 50% 50% 0px',
};
