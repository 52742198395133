import styled from 'styled-components';

const TableContainer = styled.div`
  display: block;
  overflow-x: auto;
`;
const Table = styled.table`
  width: 100%;
  max-width: 100%;
  text-align: left;
  margin: 0px;
  border-collapse: collapse;
`;
const TableHead = styled.thead`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.defaultFont};
  & tr {
    & th {
      font-weight: 400;
      padding: ${({ theme }) => theme.spaces.defaultTableHeadMargin};
      font-size: ${({ theme }) => theme.fontSizes.xs};
    }
  }
`;
const TableBody = styled.tbody`
  color: ${({ theme }) => theme.colors.secondary};

  & tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    :last-child {
      border: none;
    }
    & th {
      padding-left: ${({ theme }) => theme.spaces.md};
      font-weight: 400;
      width: 40%;
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }
    & th:last-child {
      display: flex;
      gap: ${({ theme }) => theme.spaces.md};
      width: auto;
      margin: 0;
      padding-right: ${({ theme }) => theme.spaces.md};
    }
  }
`;

export { TableContainer, Table, TableHead, TableBody };
