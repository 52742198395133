export const routes = {
  root: '/',
  state: '/',
  login: '/login',
  restorePassword: '/restore-password',
  newPassword: '/auth/reset-password/:userId/:passwordToken',
  dashboardDir: '/dashboard/*',
  dashboard: '/dashboard',
  userActivation: '/register/activation/:userId/:activationToken',
};

export const dashboardRoutes = {
  root: '/',
  state: '/dashboard',
  calendar: 'calendar',
  holiday: 'holiday',
  projects: 'projects-list',
  projectsDir: 'projects-list/*',
  project: 'project',
  projectDir: 'project/*',
  addUser: 'add-user/*',
  addProjectDir: 'add-project/*',
  addProject: 'add-project',
  statistics: 'statistics',
  user: 'user',
  userDir: 'user/*',
  usersList: 'users-list',
  usersListDir: 'users-list/*',
  usersListBasic: 'users-list/basic',
  usersListReservation: 'users-list/reservation',
  configuration: 'configuration',
  configurationDir: 'configuration/*',
  configurationDictionary: 'configuration/dictionary',
};

export const addUserRoutes = {
  root: '/',
  state: '/dashboard/add-user',
  personalData: ':userId',
  billingPeriods: ':userId/billing-period',
};

export const addProjectRoutes = {
  root: '/',
  state: '/dashboard/add-project',
  configuration: ':projectId',
  principals: ':projectId/principals',
  team: ':projectId/team',
  summary: ':projectId/summary',
};

export const projectListRoutes = {
  root: '/',
  state: '/dashboard/projects-list',
};

export const userListRoutes = {
  root: '/',
  state: '/dashboard/users-list',
  basic: 'basic',
  reservation: 'reservation',
};

export const userRoutes = {
  root: '/',
  state: '/dashboard/user',
  details: ':userId/details',
};

export const configurationRoutes = {
  root: '/',
  state: '/dashboard/configuration',
  dictionary: 'dictionary',
};

export const projectRoutes = {
  root: '/',
  state: '/dashboard/project',
  details: ':projectId/details',
};
