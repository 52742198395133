import { FC } from 'react';
import { DatePickerInput, GridItem, Input } from 'components/common';
import { GridComponent } from 'components/common/Grid/GridContainer/styles';
import { FieldErrorsImpl } from 'react-hook-form';
import { EventType } from 'types/forms';

interface Props {
  eventData?: EventType;
  errors?: FieldErrorsImpl<EventType>;
}

export const AddEvent: FC<Props> = ({ eventData, errors }) => {
  return (
    <>
      <GridComponent size={[2]}>
        <GridItem position={[1, 1]}>
          <Input
            name="title"
            labelText="label.title"
            defaultValue={eventData && eventData.title}
            errorText={errors?.title?.message}
          />
        </GridItem>
        <GridItem position={[2, 1]}>
          <DatePickerInput
            name="date"
            minDate={new Date()}
            labelText="label.date"
            placeholder="placeholder.dateFormat"
            errorText={errors?.date?.message}
          />
        </GridItem>
        <GridItem position={[1, 2, 3]}>
          <Input
            name="eventDescription"
            labelText="label.description"
            defaultValue={eventData && eventData.eventDescription}
            errorText={errors?.eventDescription?.message}
          />
        </GridItem>
      </GridComponent>
    </>
  );
};
