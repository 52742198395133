import { __ } from 'services/translation';
import * as yup from 'yup';
import { object as yupObject } from 'yup';
import { checkPositiveNumber } from '../yupValidators';

export const userInfoBasicListFilters = yupObject().shape({
  search: yup.string().notRequired(),
  positionId: yup.object().notRequired(),
  experienceLevel: yup.object().notRequired(),
  status: yup.object().notRequired(),
});

export const projectListFilters = yupObject().shape({
  search: yup.string().notRequired(),
  projectReservationsTimeRange: yup.object().notRequired(),
  projectStatus: yup.object().notRequired(),
});

export const userReservationsInfoListFilters = yupObject().shape({
  search: yup.string().notRequired(),
  positionId: yup.object().notRequired(),
  experienceLevel: yup.object().notRequired(),
  startDate: yup.string().notRequired(),
  endDate: yup.string().notRequired(),
  hoursMin: checkPositiveNumber(),
  hoursMax: checkPositiveNumber(),
});

export const userReservationsSkills = yupObject().shape({
  skillId: yup.object().notRequired(),
  min: yup.object().when('skillId', {
    is: true,
    then: yup.object().required(),
  }),
  max: yup.object().when('skillId', {
    is: true,
    then: yup.object().required(),
  }),
  status: yup.object().when('skillId', {
    is: true,
    then: yup.object().required(),
  }),
});

export const positionDictionaryFilters = yupObject().shape({
  search: yup.string().notRequired(),
});

export const skillDictionaryFilters = yupObject().shape({
  search: yup.string().notRequired(),
});
