import { lazy, Suspense, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { routes } from 'router';
import { getCurrentLanguage } from 'utils';
import { Notification } from 'services/notifications/styles';
import { LoadingPage } from 'views/LoadingPage';
import { i18n } from 'services/translation';
import { Modal } from 'components/modals/Modal';
import { store } from './config/store';
import AppConfig from './config/app';

const UnauthorizedRoute = lazy(() => import('./router/UnauthorizedRoute'));
const Login = lazy(() => import('views/Login'));
const RestorePassword = lazy(() => import('views/RestorePassword'));
const NewPassword = lazy(() => import('views/NewPassword'));
const Page404 = lazy(() => import('views/Page404'));
const UserActivation = lazy(() => import('views/UserActivation'));

const Dashboard = lazy(() => import('views/Dashboard'));

const App = () => {
  const [i18nLoaded, setI18nLoaded] = useState(i18n.loaded);
  const reloadActiveTranslations = () => {
    setI18nLoaded(true);
  };

  useLayoutEffect(() => {
    const clientLanguage = getCurrentLanguage();
    i18n.load(clientLanguage, reloadActiveTranslations);
  }, [i18nLoaded]);

  const content = i18nLoaded ? (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <AppRouting />
      </Suspense>
      <Modal />
      <Notification autoClose={AppConfig.NOTIFICATION_TIME} />
    </BrowserRouter>
  ) : (
    <LoadingPage />
  );

  return <Provider store={store}>{content}</Provider>;
};

const AppRouting = () => (
  <Routes>
    <Route path={routes.root} element={<UnauthorizedRoute />}>
      <Route path={routes.root} element={<Navigate to={routes.login} />} />
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.restorePassword} element={<RestorePassword />} />
      <Route path={routes.newPassword} element={<NewPassword />} />
      <Route path={routes.userActivation} element={<UserActivation />} />
      <Route path="*" element={<Page404 />} />
    </Route>
    <Route path={routes.dashboardDir} element={<Dashboard />} />
  </Routes>
);

export default App;
