import { __ } from 'services/translation';
import * as yup from 'yup';
import { object as yupObject } from 'yup';

export const eventSchema = yupObject().shape({
  title: yup.string().required('validation.fieldIsRequired'),
  date: yup.string().required('validation.fieldIsRequired'),
  eventDescription: yup.string().required('validation.fieldIsRequired'),
  reminderDate: yup.string().required('validation.fieldIsRequired'),
  notify: yup.string().required('validation.fieldIsRequired'),
});
