import { Input, Select } from 'components/common';
import { FC } from 'react';
import { SelectType } from 'types';
import { InputContainer, WideContainer } from './styles';

interface Props {
  inputName: string;
  selectName: string;
  placeholder?: string;
  labelText?: string;
  options: SelectType[];
  defaultValue?: SelectType;
  errorText?: string;
  inputType?: string;
}

export const InputWithSelect: FC<Props> = ({
  inputName,
  selectName,
  placeholder,
  labelText,
  options,
  defaultValue,
  errorText,
  inputType,
}) => {
  return (
    <InputContainer error={errorText ? true : false}>
      <WideContainer>
        <Input
          type={inputType}
          name={inputName}
          placeholder={placeholder}
          labelText={labelText}
          errorText={errorText}
        />
      </WideContainer>

      <Select
        name={selectName}
        placeholder={placeholder}
        options={options}
        defaultValue={defaultValue}
      />
    </InputContainer>
  );
};
