import { FC } from 'react';
import { __ } from '../../translation';

interface Props {
  heading: string;
  message: string;
  translation: boolean;
}

export const CreateMessage: FC<Props> = ({ heading, message, translation }) => (
  <>
    <h2>{__(heading)}</h2>
    <p>{translation ? __(message) : message}</p>
  </>
);
