import styled from 'styled-components';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  size: number[];
  verticalMargin?: string;
}

const GridComponent = styled.div<Props>`
  display: grid;
  margin: ${({ theme: { spaces }, verticalMargin }) =>
    verticalMargin ? `${spaces[verticalMargin]} 0px` : '0px 0px'};
  gap: ${({ theme }) => theme.spaces.md};
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ size }) => `repeat(${size[0]}, 1fr)`};
  grid-template-rows: ${({ size }) => `repeat(auto ${size[1]}, 1fr)`};
  ${ltMedia('sm')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export { GridComponent };
