import { FC, useCallback } from 'react';
import { GridItem, GridContainer, Input } from 'components/common';
import { Controller, useFormContext } from 'react-hook-form';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { formatDate, getLocale } from 'services/date';
import { DateInputType } from 'types';
import { DateWrapper } from './styles';

interface Props {
  nameStart: string;
  nameEnd: string;
  placeholder: string;
  labelTextStart: string;
  labelTextEnd: string;
  startDateError?: string;
  endDateError?: string;
}

export const RangeDatePicker: FC<Props> = ({
  nameStart,
  nameEnd,
  placeholder,
  labelTextStart,
  labelTextEnd,
  startDateError,
  endDateError,
}) => {
  const locale = getLocale();
  registerLocale(locale.code ?? 'pl', locale);

  const { getValues } = useFormContext();

  const DateInput = useCallback(
    ({ onClick, value, placeholder, labelText, name, errorText, helpText }: DateInputType) => {
      return (
        <Input
          type={value ? 'date' : 'text'}
          readOnly
          name={name}
          placeholder={placeholder}
          labelText={labelText}
          onClick={onClick}
          value={value && formatDate(value, 'inputDate')}
          errorText={errorText}
          helpText={helpText}
        />
      );
    },
    [],
  );

  return (
    <DateWrapper>
      <GridContainer size={[2, 1]}>
        <>
          <GridItem>
            <Controller
              name={nameStart}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  selectsStart
                  name={nameStart}
                  selected={value}
                  onChange={(date) => onChange(date)}
                  monthsShown={2}
                  startDate={value}
                  endDate={getValues(nameEnd)}
                  maxDate={getValues(nameEnd)}
                  calendarStartDay={1}
                  placeholderText={placeholder}
                  formatWeekDay={(weekDay) => weekDay.substring(0, 1).toUpperCase()}
                  locale="pl"
                  customInput={
                    <DateInput
                      name={nameStart}
                      placeholder={placeholder}
                      labelText={labelTextStart}
                      errorText={startDateError}
                    />
                  }
                />
              )}
            />
          </GridItem>
          <GridItem>
            <Controller
              name={nameEnd}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  selectsEnd
                  name={nameEnd}
                  selected={value}
                  onChange={(date) => onChange(date)}
                  monthsShown={2}
                  startDate={getValues(nameStart)}
                  endDate={value}
                  minDate={getValues(nameStart)}
                  calendarStartDay={1}
                  placeholderText={placeholder}
                  formatWeekDay={(weekDay) => weekDay.substring(0, 1).toUpperCase()}
                  locale="pl"
                  customInput={
                    <DateInput
                      name={nameEnd}
                      placeholder={placeholder}
                      labelText={labelTextEnd}
                      errorText={endDateError}
                    />
                  }
                />
              )}
            />
          </GridItem>
        </>
      </GridContainer>
    </DateWrapper>
  );
};
