export const sizes = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
  xxl: '32px',
  iconButtonSize: '20px',
  inputHeight: '48px',
  inputMobileHeight: '44px',
  inputIconPosition: 'calc(100% - 16px)',
  textareaHeight: '96px',
  logoHeight: '24px',
  unauthorizedBox: '398px',
  errorBoxHeight: '20px',
  primaryNav: '80px',
  secondaryNav: '268px',
  mobileNav: '64px',
  mobileLogoWidth: '110px',
  mobileLogoHeight: '36px',
  modalMinWidth: '500px',
  defaultFormBox: '724px',
  wideFormBox: '1076px',
  progressItem: '10px',
  progressBar: '144px',
  selectInDounbleInput: '116px',
};
