import { createContext, Dispatch, SetStateAction } from 'react';
import { BasePagination, PagesPossibility } from 'types';

type BasePaginationContextType = {
  pagination: BasePagination;
  setPagination: Dispatch<SetStateAction<BasePagination>>;
  pagesPossibility: PagesPossibility;
  setPagesPossibility: Dispatch<SetStateAction<PagesPossibility>>;
};

export const BasePaginationContext = createContext<BasePaginationContextType>({
  pagination: {},
  setPagination: () => {},
  pagesPossibility: { hasNextPage: false, hasPreviousPage: false },
  setPagesPossibility: () => {},
});
