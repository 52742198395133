import {
  BasePaginationContext,
  PositionDictionaryListFiltersContext,
  SkillDictionaryListFiltersContext,
} from 'context';
import { useApi } from 'hooks/useApi';
import { useModal } from 'hooks/useModal';
import { useContext } from 'react';
import { __ } from 'services/translation';
import {
  AddPositionDictionary,
  AddSkillDictionary,
  ModalType,
  PositionsDictionaryListFilters,
  SkillsDictionaryListFilters,
} from 'types';
import { prepareApiParamsObj } from 'utils';
import {
  BasePaginationRes,
  PositionBasicData,
  Role,
  SkillDiminishedData,
  SkillFullData,
  UserBasicData,
  UserPositionRes,
} from 'work-planner-backend/types';

interface UseDictionary {
  getPositionsDictionary: () => Promise<void>;
  applyPositionsDictionaryFilters: (usersFilters?: PositionsDictionaryListFilters) => Promise<void>;
  addPositionDictionary: (body: AddPositionDictionary) => Promise<void>;
  removePositionDictionary: (id: string) => void;
  editPositionDictionary: (position: UserPositionRes) => void;
  getSkillsDictionary: () => Promise<void>;
  applySkillsDictionaryFilters: (usersFilters?: SkillsDictionaryListFilters) => Promise<void>;
  addSkillDictionary: (body: AddSkillDictionary) => Promise<void>;
  removeSkillDictionary: (id: string) => void;
  editSkillDictionary: (skill: SkillFullData) => void;
}

export const useDictionary = (): UseDictionary => {
  const { apiGet, apiPost, apiDelete, apiPatch } = useApi();
  const { showModal } = useModal();
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const {
    setData: setPositionsDictionary,
    filters: positionDictionaryFilters,
    setFilters: positionDictionarySetFilters,
  } = useContext(PositionDictionaryListFiltersContext);
  const {
    setData: setSkillsDictionary,
    filters: skillDictionaryFilters,
    setFilters: skillDictionarySetFilters,
  } = useContext(SkillDictionaryListFiltersContext);

  const getPositionsDictionary = async () => {
    const result = await apiGet<BasePaginationRes<UserPositionRes>>({
      url: '/users/positions/all',
      options: { params: prepareApiParamsObj({ ...positionDictionaryFilters, ...pagination }) },
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setPositionsDictionary(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const applyPositionsDictionaryFilters = async (usersFilters?: PositionsDictionaryListFilters) => {
    const preparedFiltersData = {
      search: usersFilters?.search,
    };

    const result = await apiGet<BasePaginationRes<UserPositionRes>>({
      url: '/users/positions/all',
      options: { params: prepareApiParamsObj({ ...preparedFiltersData, ...pagination }) },
    });

    positionDictionarySetFilters(prepareApiParamsObj(preparedFiltersData));

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setPositionsDictionary(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const addPositionDictionary = async (body: AddPositionDictionary) => {
    const preparedBody: PositionBasicData = {
      positionName: body.positionName,
      forRole: body.forRole.value as Role,
    };

    const result = await apiPost<UserPositionRes, PositionBasicData>({
      url: '/users/positions',
      body: preparedBody,
      callbacks: {
        successMessage: 'notification.addedPosition',
        errorMessage: 'notification.noAddedPosition',
      },
    });

    if (result) {
      getPositionsDictionary();
    }
  };

  const removePositionDictionary = (id: string) => {
    showModal({
      modalType: ModalType.CONFIRM,
      data: {
        message: __('description.dictionaryRemove'),
      },
      callback: () => removePositionDictionaryConfirmed(id),
    });
  };

  const removePositionDictionaryConfirmed = async (id: string) => {
    const result = await apiDelete<void | UserBasicData[]>({
      url: `/users/positions/${id}`,
      callbacks: {
        successMessage: 'notification.removedPositionDictionary',
        errorMessage: 'notification.noRemovedPositionDictionary',
      },
    });
    getPositionsDictionary();
  };

  const editPositionDictionary = (position: UserPositionRes) => {
    showModal({
      modalType: ModalType.EDIT_DICTIONARY_POSITION,
      data: position,
      callback: (id: string, newValues: PositionBasicData) =>
        editPositionDictionaryConfirmed(id, newValues),
    });
  };

  const editPositionDictionaryConfirmed = async (id: string, newValues: PositionBasicData) => {
    const result = await apiPatch<UserPositionRes, PositionBasicData>({
      url: `/users/positions/${id}`,
      body: newValues,
      callbacks: {
        successMessage: 'notification.editedPositionDictionary',
        errorMessage: 'notification.noEditedPositionDictionary',
      },
    });

    if (result) {
      getPositionsDictionary();
    }
  };

  //@ Skill

  const getSkillsDictionary = async () => {
    const result = await apiGet<BasePaginationRes<SkillFullData>>({
      url: '/skills',
      options: { params: prepareApiParamsObj({ ...skillDictionaryFilters, ...pagination }) },
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setSkillsDictionary(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const applySkillsDictionaryFilters = async (usersFilters?: SkillsDictionaryListFilters) => {
    const preparedFiltersData = {
      search: usersFilters?.search,
    };

    const result = await apiGet<BasePaginationRes<SkillFullData>>({
      url: '/skills',
      options: { params: prepareApiParamsObj({ ...preparedFiltersData, ...pagination }) },
    });

    skillDictionarySetFilters(prepareApiParamsObj(preparedFiltersData));

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setSkillsDictionary(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const addSkillDictionary = async (body: AddSkillDictionary) => {
    const preparedBody: SkillDiminishedData = {
      name: body.name,
    };

    const result = await apiPost<SkillFullData, SkillDiminishedData>({
      url: '/skills',
      body: preparedBody,
      callbacks: {
        successMessage: 'notification.addedSkill',
        errorMessage: 'notification.noAddedSkill',
      },
    });

    if (result) {
      getSkillsDictionary();
    }
  };

  const removeSkillDictionary = (id: string) => {
    showModal({
      modalType: ModalType.CONFIRM,
      data: {
        message: __('description.dictionaryRemove'),
      },
      callback: () => removeSkillDictionaryConfirmed(id),
    });
  };

  const removeSkillDictionaryConfirmed = async (id: string) => {
    await apiDelete<void>({
      url: `/skills/${id}`,
      callbacks: {
        successMessage: 'notification.removedSkillDictionary',
        errorMessage: 'notification.noRemovedSkillDictionary',
      },
    });
    getSkillsDictionary();
  };

  const editSkillDictionary = (skill: SkillFullData) => {
    showModal({
      modalType: ModalType.EDIT_DICTIONARY_SKILL,
      data: skill,
      callback: (id: string, newValues: SkillDiminishedData) =>
        editSkillDictionaryConfirmed(id, newValues),
    });
  };

  const editSkillDictionaryConfirmed = async (id: string, newValues: SkillDiminishedData) => {
    const result = await apiPatch<SkillFullData, SkillDiminishedData>({
      url: `/skills/${id}`,
      body: newValues,
      callbacks: {
        successMessage: 'notification.editedSkillDictionary',
        errorMessage: 'notification.noEditedSkillDictionary',
      },
    });

    if (result) {
      getSkillsDictionary();
    }
  };

  return {
    getPositionsDictionary,
    applyPositionsDictionaryFilters,
    addPositionDictionary,
    removePositionDictionary,
    editPositionDictionary,
    getSkillsDictionary,
    applySkillsDictionaryFilters,
    addSkillDictionary,
    removeSkillDictionary,
    editSkillDictionary,
  };
};
