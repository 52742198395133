import styled, { css } from 'styled-components';

interface Props {
  error?: boolean;
}

const baseNavArrow = css`
  z-index: 1;
  width: ${({ theme: { sizes } }) => sizes.xl};
  height: ${({ theme: { sizes } }) => sizes.xl};
  border: none;
`;

const DateWrapper = styled.div<Props>`
  .react-datepicker {
    display: flex;
    background-color: ${({ theme: { colors } }) => colors.light};
    box-shadow: ${({ theme: { colors } }) => colors.boxShadow};

    .react-datepicker__header {
      ::first-letter {
        text-transform: uppercase;
      }
      text-align: center;
    }
  }

  .react-datepicker__month-container {
    padding: ${({ theme: { spaces } }) => spaces.md};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    display: inline-block;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.dark};
    width: ${({ theme: { sizes } }) => sizes.xxl};
    line-height: ${({ theme: { sizes } }) => sizes.xxl};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  }
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__current-month {
    margin-bottom: ${({ theme: { spaces } }) => spaces.md};
    color: ${({ theme: { colors } }) => colors.dark};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .react-datepicker__navigation {
    cursor: pointer;
    position: absolute;
    top: ${({ theme: { sizes } }) => sizes.md};
    & span {
      display: none;
    }
  }
  .react-datepicker__navigation--previous {
    background: url(../icons/arrowLeft.svg) no-repeat;
    left: ${({ theme: { sizes } }) => sizes.lg};
    ${baseNavArrow};
  }
  .react-datepicker__navigation--next {
    background: url(../icons/arrowRight.svg) no-repeat;
    right: ${({ theme: { sizes } }) => sizes.lg};
    ${baseNavArrow};
  }

  .react-datepicker__day--selected {
    color: ${({ theme: { colors } }) => colors.light};
    background-color: ${({ theme: { colors } }) => colors.primary};
    border-radius: ${({ theme: { radius } }) => radius.oval};
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled) {
    cursor: pointer;
    :hover {
      color: ${({ theme: { colors } }) => colors.light};
      background-color: ${({ theme: { colors } }) => colors.defaultDateRange};
      border-radius: ${({ theme: { radius } }) => radius.oval};
    }
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__week {
    padding: ${({ theme: { spaces } }) => spaces.xs} 0px;
  }
  .react-datepicker__day--disabled {
    color: ${({ theme: { colors } }) => colors.defaultFont};
    cursor: default;
  }
`;

export { DateWrapper };
