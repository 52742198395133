import AppConfig from 'config/app';
import { format, lastDayOfMonth, startOfMonth } from 'date-fns';

export const preparedApiDataFormat = (date?: Date | string | number) =>
  date ? format(new Date(date), AppConfig.DATE_FORMAT_INPUT) : undefined;

export const setDefaultStartDateIfNotChosen = (date?: Date | string | number) =>
  date ? preparedApiDataFormat(new Date(date)) : preparedApiDataFormat(startOfMonth(new Date()));

export const setDefaultLastDateIfNotChosen = (date?: Date | string | number) =>
  date ? preparedApiDataFormat(new Date(date)) : preparedApiDataFormat(lastDayOfMonth(new Date()));
