import {
  BasePaginationRes,
  CreateOrEditReservation,
  EmployeeAvailabilityRes,
  ExtendedReservationRes,
  UserDiminishedData,
} from 'work-planner-backend/types';
import { format } from 'date-fns';
import { __ } from 'services/translation';
import { useContext } from 'react';
import { BasePagination, ModalType, ProjectWorkersFilters, UserReservation } from 'types';
import AppConfig from 'config/app';
import { filterEmptyParams, prepareURLParamsString } from 'utils/queryParams';
import { BasePaginationContext, ProjectAddWorkersContext } from 'context';
import { useApi } from '../useApi';
import { useModal } from '../useModal';

interface UseWorkersAddProject {
  getAvailableWorkers: (pagination?: BasePagination) => Promise<void>;
  applyWorkerFilters: (pagination?: BasePagination & ProjectWorkersFilters) => Promise<void>;
  getWorker: (userId: string) => Promise<void>;
}

export const useWorkersAddProject = (): UseWorkersAddProject => {
  const { apiGet, apiPost } = useApi();
  const { showModal, hideModal } = useModal();
  const { pagination, setPagesPossibility } = useContext(BasePaginationContext);
  const { setWorkers, projectId, setWorker, setFilters, filters } =
    useContext(ProjectAddWorkersContext);

  const preparedApiDataFormat = (date?: Date | string | number) =>
    date ? format(new Date(date), AppConfig.DATE_FORMAT_INPUT) : undefined;

  const getAvailableWorkers = async (pagination?: BasePagination) => {
    const queryParams = prepareURLParamsString({ ...filters, ...pagination }, { encode: false });
    const result = await apiGet<BasePaginationRes<UserDiminishedData> | null>({
      url: `projects/reservations/available-users/${projectId}${queryParams}`,
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setWorkers(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const setReservation = async (
    { endDate, startDate, hoursPerDay }: UserReservation,
    userId: string,
  ) => {
    const body = {
      hoursPerDay,
      endDate: format(new Date(endDate), AppConfig.DATE_FORMAT_INPUT),
      startDate: format(new Date(startDate), AppConfig.DATE_FORMAT_INPUT),
    };
    const result = await apiPost<ExtendedReservationRes, CreateOrEditReservation>({
      body,
      url: `projects/reservations/${projectId}/${userId}`,
    });

    if (result) {
      getAvailableWorkers(pagination);
      hideModal();
    }
  };

  const getWorker = async (userId: string) => {
    const result = await apiGet<EmployeeAvailabilityRes>({
      url: `projects/reservations/users/${projectId}/${userId}`,
    });

    if (result) {
      setWorker(result);
      showModal({
        header: __('heading.reservation'),
        modalType: ModalType.USER_AVAILABLE,
        data: result,
        callback: (reservation: UserReservation) => setReservation(reservation, userId),
      });
    }
  };

  const applyWorkerFilters = async (workerFilters?: ProjectWorkersFilters) => {
    const preparedSkills = workerFilters?.skills?.map(({ skillId, status, max, min }) => ({
      max: max.value,
      min: min.value,
      status: status.value,
      skillId: skillId.value,
    }));

    console.log(workerFilters);

    const preparedFiltersData = {
      endDate: preparedApiDataFormat(workerFilters?.endDate),
      startDate: preparedApiDataFormat(workerFilters?.startDate),
      skills: preparedSkills,
      experienceLevel: workerFilters?.experienceLevel?.value,
      positionId: workerFilters?.positionId?.value,
    };

    const queryParams = prepareURLParamsString(preparedFiltersData, { encode: false });

    const result = await apiGet<BasePaginationRes<UserDiminishedData> | null>({
      url: `projects/reservations/available-users/${projectId}${queryParams}`,
    });

    setFilters(filterEmptyParams(preparedFiltersData));

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setWorkers(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  return {
    getAvailableWorkers,
    getWorker,
    applyWorkerFilters,
  };
};
