import styled, { css } from 'styled-components';
import { HeadingType } from 'types';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  margin?: string;
  mobileMargin?: string;
  variant?: HeadingType;
}

const BasicHeading = styled.h2<Props>`
  display: flex;
  color: ${({ theme }) => theme.colors.dark};
  margin: ${({ theme: { spaces }, margin }) => (margin ? spaces[margin] : spaces.smBottomMargin)};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.mLg};
  font-weight: 600;
  ${({ variant }) => {
    switch (variant) {
      case 'unauthorized':
        return css`
          margin: ${({ theme: { spaces } }) => spaces.mdBottomMargin};
          font-size: ${({ theme: { fontSizes } }) => fontSizes.xxl};
          font-weight: 500;
          ${ltMedia('sm')} {
            font-size: ${({ theme: { fontSizes } }) => fontSizes.xl};
          }
        `;
      case 'subheading':
        return css`
          font-weight: 500;
          font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
          ${ltMedia('sm')} {
            font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
          }
        `;
    }
  }};

  ${ltMedia('sm')} {
    margin: ${({ theme, mobileMargin }) =>
      mobileMargin ? theme.spaces[mobileMargin] : theme.spaces.xlBottomMargin};
  }
`;

const Count = styled.div`
  display: flex;
  height: ${({ theme }) => theme.sizes.xl};
  width: ${({ theme }) => theme.sizes.xl};
  margin-left: ${({ theme }) => theme.spaces.defaultLabelDisplacement};
  align-self: center;
  color: ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radius.oval};
  & > p {
    width: 100%;
    align-self: center;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export { BasicHeading, Count };
