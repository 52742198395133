import styled, { css } from 'styled-components';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  error?: boolean;
  placeholderRight?: boolean;
}

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputComponent = styled.input<Props>`
  width: 100%;
  height: ${({ theme: { sizes } }) => sizes.inputHeight};
  padding: ${({ theme: { spaces } }) => spaces.defaultPadding};
  border-radius: ${({ theme: { radius } }) => radius.sm};
  border: 1px solid ${({ theme: { colors }, error }) => (error ? colors.error : colors.border)};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
  background-color: white;
  text-overflow: ellipsis;
  margin-top: ${({ theme: { spaces } }) => spaces.sm};
  :focus {
    border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
    & + label {
      color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
    }
  }
  :disabled {
    ::placeholder {
      color: ${({ theme: { colors } }) => colors.border};
    }
  }

  ::placeholder {
    text-align: ${({ placeholderRight }) => (placeholderRight ? 'right' : 'left')};
  }

  ${ltMedia('sm')} {
    height: ${({ theme: { sizes } }) => sizes.inputMobileHeight};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  }
`;

export const InputLabel = styled.label<Props>`
  position: relative;
  display: block;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
  padding: 0px ${({ theme: { spaces } }) => spaces.xs};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  transform: translate(${({ theme: { spaces } }) => spaces.desktopDisplacement});
  background-color: white;
  color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.dark)};
  ${ltMedia('sm')} {
    transform: translate(${({ theme: { spaces } }) => spaces.mobileDisplacement});
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  width: fit-content;
  top: ${({ theme: { spaces } }) => spaces.inputIconTop};
  right: ${({ theme: { spaces } }) => spaces.inputIconRight};
  cursor: pointer;
`;
