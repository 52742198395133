import { object as yupObject } from 'yup';
import {
  checkName,
  checkSurname,
  checkRepeatedPassword,
  checkPassword,
  checkEmail,
} from '../yupValidators';

export const registerSchema = yupObject().shape({
  email: checkEmail(),
  password: checkPassword(),
  passwordRepeat: checkRepeatedPassword(),
  firstName: checkName(),
  lastName: checkSurname(),
});
