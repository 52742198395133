import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridComponent } from 'components/common/Grid/GridContainer/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { EditPositionDictionary } from 'types';
import { __ } from 'services/translation';
import { PositionBasicData, Role } from 'work-planner-backend/types';
import { editPositionDictionarySchema } from 'services/formValidator/schemas';
import { getSelectOptionsFromEnum } from 'utils';
import { useModal } from 'hooks';
import { Button, Form, GridItem, Input, Select } from 'components/common';
import { ButtonContainer } from './styles';

interface PositionDictionaryEditData {
  positionName: string;
  forRole: Role;
  id: string;
}

export interface PositionDictionaryEditProps {
  data?: PositionDictionaryEditData;
  callback?: (id: string, newValues: PositionBasicData) => void;
}

export const PositionDictionaryEdit: FC<PositionDictionaryEditProps> = ({ callback, data }) => {
  const { hideModal } = useModal();

  const methods = useForm<EditPositionDictionary>({
    mode: 'onSubmit',
    resolver: yupResolver(editPositionDictionarySchema),
  });

  if (!callback || !data) {
    hideModal();
    return <></>;
  }

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    hideModal();
  };

  const handleUpdate = (dataSubmit: EditPositionDictionary) => {
    const { forRole, positionName } = dataSubmit;
    const preparedData: PositionBasicData = { forRole: forRole.value as Role, positionName };
    callback(data.id, preparedData);
    hideModal();
  };

  useEffect(() => {
    if (data) {
      const { positionName, forRole } = data;
      setValue('positionName', positionName);
      setValue('forRole', { value: forRole, label: __(`enum.${forRole.toLocaleLowerCase()}`) });
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleUpdate)}>
        <GridComponent size={[2, 1]}>
          <GridItem position={[1, 1]}>
            <Input
              labelText="label.positionName"
              name="positionName"
              errorText={errors?.positionName?.message}
            />
          </GridItem>
          <GridItem position={[2, 1]}>
            <Select
              labelText="label.forRole"
              name="forRole"
              options={getSelectOptionsFromEnum(Role, true)}
              errorText={errors?.forRole?.message}
            />
          </GridItem>
        </GridComponent>
        <ButtonContainer center>
          <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
          <Button text="button.saveChanges" variant="primary" type="submit" />
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};
