import styled, { css } from 'styled-components';
import { gtMedia } from 'config/theme/mediaQuery';

interface Props {
  position?: number[];
}

const GridItemComponent = styled.div<Props>`
  ${gtMedia('sm')} {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    ${({ position }) =>
      position &&
      css`
        grid-column-start: ${position[0]};
        grid-column-end: ${position[2] ? position[2] : position[0]};
        grid-row: ${position[1]};
      `}
  }
  width: 100%;
`;

export { GridItemComponent };
