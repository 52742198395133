import { differenceInBusinessDays, format } from 'date-fns';
import { pl } from 'date-fns/locale';
import CONFIG from 'config/app';

export const getToday = () => {
  return new Date();
};

export const formatDate = (
  date: Date | string | number,
  type: 'date' | 'time' | 'day' | 'longDatePl' | 'month' | 'inputDate' | 'dayMonthYearPl',
) => {
  const newDate = typeof date === 'string' ? new Date(date) : date;
  try {
    switch (type) {
      case 'date':
        return format(newDate, CONFIG.DATE_FORMAT);
      case 'longDatePl':
        return format(newDate, CONFIG.LONG_DATE_FORMAT, { locale: pl });
      case 'dayMonthYearPl':
        return format(newDate, CONFIG.DAY_MONTH_YEAR, { locale: pl });
      case 'time':
        return format(newDate, CONFIG.TIME_FORMAT);
      case 'day':
        return format(newDate, CONFIG.DAY_FORMAT);
      case 'month':
        return format(newDate, CONFIG.MONTH_FORMAT, { locale: pl });
      case 'inputDate':
        return format(newDate, CONFIG.DATE_FORMAT_INPUT);
      default:
        return '';
    }
  } catch (e) {
    return '_';
  }
};

export const getLocale = () => pl;

export const calculateWorkHoursInRange = (startDate?: Date, endDate?: Date) => {
  if (startDate && endDate) {
    return differenceInBusinessDays(endDate, startDate) * 8;
  }

  return 0;
};
