import { ProjectsFullData } from './projects';

export enum EventNotificationType {
  EveryDay = 'EVERY_DAY',
  ChosenDay = 'CHOSEN_DAY',
}

export interface ProjectsEventsFullData {
  id: string;
  title: string;
  description: string;
  startDate: string | Date;
  reminderDate: string | Date;
  notificationType: EventNotificationType;
  project: ProjectsFullData;
}

export type ProjectsEventsBaseData = Omit<ProjectsEventsFullData, 'project'>;

export type CreateEvent = Omit<ProjectsEventsFullData, 'id' | 'project'>;
