import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EmployeeAvailabilityRes, EmployeeFreePeriod } from 'work-planner-backend/types';
import { Button, GridContainer, GridItem, Input, List, RangeDatePicker } from 'components/common';
import { UserReservation } from 'types';
import { thirdStepReservation } from 'services/formValidator/schemas/projectConfiguration';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from 'hooks';
import { DateHeading, DateInListContainer, FormReservation, FreePeriodsItem } from './styles';

export interface UserAvailableProps {
  data?: EmployeeAvailabilityRes;
  callback?: (reservation: UserReservation) => void;
}

export const UserAvailable: FC<UserAvailableProps> = ({ data, callback }) => {
  const { hideModal } = useModal();
  const methods = useForm<UserReservation>({
    mode: 'onSubmit',
    resolver: yupResolver(thirdStepReservation),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  if (!callback) {
    hideModal();
    return <></>;
  }

  const handleOnClickItem = ({ endDate, startDate, freeHoursPerDay }: EmployeeFreePeriod) => {
    setValue('endDate', new Date(endDate));
    setValue('startDate', new Date(startDate));
    setValue('hoursPerDay', freeHoursPerDay);
  };

  const renderList = data?.freePeriods.map((reservation) => (
    <FreePeriodsItem key={reservation.startDate} onClick={() => handleOnClickItem(reservation)}>
      <DateInListContainer size={[3]}>
        <DateHeading
          title="label.startDate"
          description={reservation.startDate}
          marginBottom="xs"
        />
        <DateHeading title="label.endDate" description={reservation.endDate} marginBottom="xs" />
        <DateHeading
          title="label.possibilityHours"
          description={`${reservation.freeHoursPerDay}`}
          marginBottom="xs"
        />
      </DateInListContainer>
    </FreePeriodsItem>
  ));

  return (
    <>
      <List>{renderList}</List>
      <FormProvider {...methods}>
        <FormReservation>
          <GridContainer size={[2, 2]}>
            <GridItem position={[1, 1]}>
              <RangeDatePicker
                nameStart="startDate"
                nameEnd="endDate"
                placeholder="placeholder.dateFormat"
                labelTextStart="label.startDate"
                labelTextEnd="label.endDate"
                startDateError={errors?.startDate?.message}
                endDateError={errors?.endDate?.message}
              />
            </GridItem>
            <GridItem position={[3, 1]}>
              <Input
                labelText="label.hoursPerDay"
                name="hoursPerDay"
                errorText={errors?.hoursPerDay?.message}
              />
            </GridItem>
          </GridContainer>
          <GridItem position={[3, 2]}>
            <Button onClick={handleSubmit(callback)} text="button.reserve" variant="primaryFixed" />
          </GridItem>
        </FormReservation>
      </FormProvider>
    </>
  );
};
