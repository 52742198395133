import { ProjectBudgetHistoryFullData } from './projects-budget-history';
import { ProjectsFullData } from './projects';

export enum BudgetType {
  PerMonth = 'PER_MONTH',
  PerProject = 'PER_PROJECT',
}

export enum CurrencyCode {
  Pln = 'PLN',
  Eur = 'EUR',
  Usd = 'USD',
  Gbp = 'GBP',
  Chf = 'CHF',
}

export type CreateBudgetDetails = Omit<
  ProjectsBudgetDetailsFullData,
  'id' | 'amount' | 'minimalProfit' | 'history' | 'project'
>;

export interface ProjectsBudgetDetailsFullData {
  id: string;
  amount: number;
  originalAmount: number;
  minimalProfit: number;
  originalMinimalProfit: number;
  hours: number;
  originalAmountCurrencyCode: CurrencyCode;
  originalMinimalProfitCurrencyCode: CurrencyCode;
  budgetType: BudgetType;
  history: ProjectBudgetHistoryFullData[];
  project: ProjectsFullData;
}

export type ProjectsBudgetDetailsBaseData = Omit<
  ProjectsBudgetDetailsFullData,
  'history' | 'project'
>;
