import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiWithBodyArgs, Callbacks } from 'services/api/interfaces';
import { apiGet, apiPost } from 'services/api';
import { ExperienceLevel, Role } from 'work-planner-backend/types';

export interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  position: ExperienceLevel;
}

export interface LoginBody {
  email: string;
  password: string;
}

export const login = createAsyncThunk(
  'login',
  async (postArgs: ApiWithBodyArgs<UserData, LoginBody>) => apiPost<UserData, LoginBody>(postArgs),
);

export const logout = createAsyncThunk('logout', async () =>
  apiPost({
    url: 'auth/logout',
    callbacks: {
      successMessage: 'notification.logoutCorrect',
    },
  }),
);

export const getUserData = createAsyncThunk('getUser', async (callbacks: Callbacks<UserData>) =>
  apiGet<UserData>({
    url: 'auth/me',
    callbacks,
  }),
);
