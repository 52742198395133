export enum ApiErrorCode {
  OtherError,
  FormValidationError,
  UserNotFound,
  EmailIsNotAvailable,
  WrongToken,
  PasswordIsIncorrect,
  LoginDataIsIncorrect,
  NoSuchObjectWithThisId,
  FileIsTooLarge,
  NeedAtLeastOneOption,
  CannotDeleteUnitBecauseUsedInAnotherModule,
  CannotDeleteComponentUsedInRecipe,
  ProjectNameIsTaken,
  DateIsIncorrect,
  DateIsAlreadyTaken,
  ObjectWithThisNameAlreadyExists,
  DataIsInconsistent,
  NoDataFromNbpApi,
  InvalidLanguage,
  TokenExpired,
  AccountIsNotActivated,
  CannotProperlySaveData,
  PositionNameIsTaken,
  ReservationsConflict,
  NoGivenTimeRangeToSpecifyAvailableEmployees,
  BillingPeriodsConflict,
  ReservationBeforeContract,
  LackInBillingPeriodsDataDuringGivenPeriod,
  GivenUserAlreadyHasThisSkill,
  ReservationOutOfProjectDates,
  OneOfBillingPeriodsRangeExceeded,
  DuplicatedTechnologies,
  CannotChangeStartDateOrSprintStartDateToEarlierThanToday,
  CannotRemoveProjectWithReservations,
  ProjectClientAlreadyExists,
  ProjectHasNoClient,
  ClientHasNoAgents,
  PmRoleIsNotAnAdmin,
  CannotBookAdminOrSuperadmin,
  CannotReplaceWithTheSamePm,
  DateOutOfProject,
  OverlappingReplacements,
  AdminRoleHasNoPositionsConnected,
  PositionNotConnectedWithUserRole,
  PmRequired,
  AccountIsArchived,
  UserAlreadyArchived,
  UserIsNotArchived,
  CannotRemovePastUserArchiving,
  CannotRestoreUserBeforeArchiving,
  CannotRemovePastUserRestoration,
  CannotRestoreBlockedUser,
  AccountIsManuallyBlocked,
  CannotManuallyBlockArchivedUser,
  CannotRestoreArchivedUser,
  CannotRestoreUserIfIsNotBlocked,
  CannotLinkWithManuallyBlockedPm,
  CannotLinkWithArchivedPm,
  CannotReserveArchivedUser,
  CannotReserveManuallyBlockedUser,
  DatesRangeShouldBeSpecified,
  EmailSendingFailure,
  UserIsAlreadyActivated,
  ThisUserDoesNotHaveSuchNotification,
  CannotFoundUserNotification,
  CannotAddUserBecauseOfNoActiveSuperAdminToProvideFinancialData,
}
