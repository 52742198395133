import { FC, useState, ReactNode } from 'react';

import { GridContainer, GridItem, Heading, Icon } from 'components/common';

import { PersonalDataBar, IconContainer, AccordionContainer } from './styles';

interface Props {
  heading: string;
  children: ReactNode;
}

export const Accordion: FC<Props> = ({ heading, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnOpen = () => setIsOpen((prev) => !prev);

  return (
    <AccordionContainer>
      <GridContainer size={[1]}>
        <GridItem onClick={handleOnOpen}>
          <PersonalDataBar size={[2, 1]}>
            <GridItem position={[1, 1]}>
              <Heading text={heading} variant="subheading" margin="0" />
            </GridItem>
            <GridItem position={[2, 1]}>
              <IconContainer>
                <Icon icon={isOpen ? 'arrowUp' : 'arrowDown'} />
              </IconContainer>
            </GridItem>
          </PersonalDataBar>
        </GridItem>
        {isOpen && children}
      </GridContainer>
    </AccordionContainer>
  );
};
