import { ExperienceLevel } from 'work-planner-backend/types';
import { SelectType } from 'types';

export const experienceOptions: SelectType[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const positionOptions: SelectType[] = [
  { label: 'Front-End', value: 'Front-End' },
  { label: 'Back-End', value: 'Back-End' },
];

export const experienceLevel: SelectType[] = [
  { label: 'Intern', value: ExperienceLevel.Intern },
  { label: 'Junior', value: ExperienceLevel.Junior },
  { label: 'Junior +', value: ExperienceLevel.JuniorPlus },
  { label: 'Mid', value: ExperienceLevel.Mid },
  { label: 'Mid +', value: ExperienceLevel.MidPlus },
  { label: 'Senior', value: ExperienceLevel.Senior },
  { label: 'Head', value: ExperienceLevel.Head },
];
