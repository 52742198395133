import styled from 'styled-components';

interface Props {
  marginBottom?: string;
}

export const TextBox = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme: { spaces }, marginBottom }) =>
    marginBottom ? spaces[marginBottom] : spaces.xl};
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.defaultFont};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: normal;
    margin: 0px;
  }
  p {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
