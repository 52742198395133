import { FC } from 'react';
import { TranslateText } from 'components/common';
import { BasicText, SpanText } from './styles';

interface Props {
  text: string;
  tag?: 'span' | 'p';
  size?: string;
  isBold?: boolean;
  noTranslate?: boolean;
  margin?: string;
  className?: string;
}

export const Text: FC<Props> = ({
  text,
  tag = 'p',
  noTranslate,
  size,
  isBold,
  margin,
  className,
}) =>
  tag === 'p' ? (
    <BasicText size={size} isBold={isBold} margin={margin} className={className}>
      <TranslateText text={text} noTranslate={noTranslate} />
    </BasicText>
  ) : (
    <SpanText size={size} isBold={isBold} margin={margin} className={className}>
      <TranslateText text={text} noTranslate={noTranslate} />
    </SpanText>
  );
