import { FC, useState } from 'react';
import { components, MultiValueRemoveProps } from 'react-select';
import { Controller } from 'react-hook-form';
import { __ } from 'services/translation';
import { Icon } from 'components/common';
import { SelectGroupType, SelectType } from 'types';
import { ErrorText } from '../styles';
import { SelectLabel, SelectComponent } from './styles';

interface Props {
  name: string;
  labelText?: string;
  options?: SelectType[];
  errorText?: string;
  placeholder?: string;
  groupOptions?: SelectGroupType[];
  value?: SelectType | null;
  defaultValue?: SelectType;
  multi?: boolean;
  setMultiItems?: (item: SelectType) => void;
  setItem?: (item: SelectType) => void;
}

export const Select: FC<Props> = ({
  name,
  labelText,
  options,
  placeholder,
  errorText,
  groupOptions,
  value,
  defaultValue,
  multi = false,
  setMultiItems,
  setItem,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const DropdownIndicator = () => {
    return isOpen ? <Icon icon="arrowUp" /> : <Icon icon="arrowDown" />;
  };

  const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
    return (
      <components.MultiValueRemove {...props}>
        <Icon icon="remove" />
      </components.MultiValueRemove>
    );
  };

  const preparedOptions = () =>
    groupOptions
      ? groupOptions?.map((group) => ({
          label: group.label,
          options: group.options.map((option) => ({
            label: option.label,
            value: option.value,
          })),
        }))
      : options?.map((item) => ({
          label: item.label,
          value: item.value,
        }));

  const handleChange = (option: SelectType) => {
    if (setMultiItems) {
      return setMultiItems(option);
    }
    if (setItem) {
      return setItem(option);
    }
  };
  return (
    <>
      {labelText && (
        <SelectLabel htmlFor={name} error={Boolean(errorText)} focus={isFocused}>
          {__(labelText)}
        </SelectLabel>
      )}
      <Controller
        name={name}
        render={({ field: { onChange, value } }) => (
          <SelectComponent
            className="select"
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            components={{ DropdownIndicator, MultiValueRemove }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            classNamePrefix="select"
            maxMenuHeight={120}
            value={value}
            defaultValue={defaultValue}
            error={Boolean(errorText)}
            placeholder={placeholder ? __(placeholder) : ''}
            onChange={(option) => {
              onChange(option);
              handleChange(option as SelectType);
            }}
            options={preparedOptions()}
            isMulti={multi}
          />
        )}
      />
      {errorText && <ErrorText data-testid="errorMessage">{__(errorText)}</ErrorText>}
    </>
  );
};
