import { FC } from 'react';
import { Text } from 'components/common';
import { ModalHeader } from './styles';

interface HeaderProps {
  text: string;
}

export const Header: FC<HeaderProps> = ({ text }) => {
  return (
    <ModalHeader>
      <Text size="lg" isBold text={text} />
    </ModalHeader>
  );
};
