import { createContext, Dispatch, SetStateAction } from 'react';
import {
  BasePagination,
  BaseUserDataToList,
  BillingPeriodsPerHourRes,
  BillingPeriodsPerMonthRes,
  ProjectListRes,
  SkillFullData,
  UserPositionRes,
  UsersInvolvement,
} from 'work-planner-backend/types';
import {
  BillingPeriodsListFilters,
  PositionsDictionaryListFilters,
  ProjectListFilters,
  SkillsDictionaryListFilters,
  UserBasicInfoListFilters,
  UserReservationsInfoListFilters,
} from 'types';

type FiltersContextType<T = Record<string, unknown>[], F = Record<string, unknown>> = {
  data: T[];
  setData: Dispatch<SetStateAction<T[]>>;
  filters: BasePagination & F;
  setFilters: Dispatch<SetStateAction<F>>;
};

export const UserBasicListFiltersContext = createContext<
  FiltersContextType<BaseUserDataToList, UserBasicInfoListFilters>
>({
  data: [],
  setData: () => {},
  filters: {},
  setFilters: () => {},
});

export const ProjectsFiltersContext = createContext<
  FiltersContextType<ProjectListRes, ProjectListFilters>
>({
  data: [],
  setData: () => {},
  filters: {},
  setFilters: () => {},
});

export const UserReservationsListFiltersContext = createContext<
  FiltersContextType<UsersInvolvement, UserReservationsInfoListFilters>
>({
  data: [],
  setData: () => {},
  filters: {},
  setFilters: () => {},
});

export const BillingPeriodsListFiltersContext = createContext<
  FiltersContextType<
    BillingPeriodsPerHourRes | BillingPeriodsPerMonthRes,
    BillingPeriodsListFilters
  >
>({
  data: [],
  setData: () => {},
  filters: {},
  setFilters: () => {},
});

export const PositionDictionaryListFiltersContext = createContext<
  FiltersContextType<UserPositionRes, PositionsDictionaryListFilters>
>({
  data: [],
  setData: () => {},
  filters: {},
  setFilters: () => {},
});

export const SkillDictionaryListFiltersContext = createContext<
  FiltersContextType<SkillFullData, SkillsDictionaryListFilters>
>({
  data: [],
  setData: () => {},
  filters: {},
  setFilters: () => {},
});
