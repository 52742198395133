import { RGBColor, HSLColor } from 'react-color';

//@That function does not protect before wrong format of hex. Be sure you pass correct hex format of color
export const convertFromHEXToRGB = (colorHex: string): RGBColor => {
  let part = colorHex.substring(1).split('');
  if (part.length === 3) {
    part = [part[0], part[0], part[1], part[1], part[2], part[2]];
  }
  const result: unknown = '0x' + part.join('');
  return {
    r: ((result as number) >> 16) & 255,
    g: ((result as number) >> 8) & 255,
    b: result as number & 255,
  };
};

//@That function does not protect before wrong format of hex. Be sure you pass correct hex format of color
export const convertFromHEXToHSL = (colorHex: string): HSLColor => {
  let { r, g, b } = convertFromHEXToRGB(colorHex);
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = (max + min) / 2;
  let s = (max + min) / 2;
  let l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return {
    h,
    s,
    l,
  };
};
