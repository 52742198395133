import { createContext, Dispatch, SetStateAction } from 'react';
import {
  BasePagination,
  EmployeeAvailabilityRes,
  UserDiminishedData,
} from 'work-planner-backend/types';
import { ProjectWorkersFilters } from 'types';

type ProjectWorkersContextType = {
  projectId: string;
  workers: UserDiminishedData[];
  setWorkers: Dispatch<SetStateAction<UserDiminishedData[]>>;
  worker: EmployeeAvailabilityRes;
  setWorker: Dispatch<SetStateAction<EmployeeAvailabilityRes>>;
  filters: BasePagination & ProjectWorkersFilters;
  setFilters: Dispatch<SetStateAction<Record<string, string>>>;
};

export const ProjectAddWorkersContext = createContext<ProjectWorkersContextType>({
  projectId: '',
  workers: [],
  setWorkers: () => {},
  worker: {
    reservations: [],
    freePeriods: [],
  },
  setWorker: () => {},
  filters: {},
  setFilters: () => {},
});
