import { __ } from 'services/translation';
import * as yup from 'yup';

export const checkName = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .matches(/^([\p{L}\p{M}]+\s?)*([\p{L}\p{M}]+\s?)+$/u, 'validation.onlyLetters')
    .min(3, 'validation.textTooShort')
    .max(60, 'validation.textTooLong');

export const checkSurname = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .matches(/^([\p{L}\p{M}]+\s?)*([\p{L}\p{M}]+\s?)+$/u, 'validation.onlyLetters')
    .max(60, 'validation.textTooLong');

export const checkPassword = () =>
  yup
    .string()
    .min(6, 'validation.passwordTooShort')
    .max(128, 'validation.passwordTooLong')
    .required('validation.fieldIsRequired');

export const checkRepeatedNewPassword = () =>
  yup.string().oneOf([yup.ref('newPassword')], 'validation.passwordsMustMatch');

export const checkRepeatedPassword = () =>
  yup.string().oneOf([yup.ref('password')], 'validation.passwordsMustMatch');

export const checkEmail = () =>
  yup
    .string()
    .required('validation.fieldIsRequired')
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
      'validation.invalidEmail',
    )
    .max(254, 'validation.emailTooLong');

export const checkStartDate = () =>
  yup.date().max(yup.ref('endDate'), 'validation.endDate').typeError('validation.fullDate');

export const checkEndDate = () =>
  yup.date().min(yup.ref('startDate'), 'validation.startDate').typeError('validation.fullDate');

export const checkPositiveNumber = () =>
  yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1, 'validation.minNumber')
    .notRequired();

export const checkPhoneNumber = () =>
  yup
    .string()
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .nullable()
    .notRequired()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'validation.wrongPhone',
    )
    .min(3, 'validation.textTooShort')
    .max(15, 'validation.textTooLong');
