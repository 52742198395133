import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Form } from 'components/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { eventSchema } from 'services/formValidator/schemas/event';
import { useModal } from 'hooks';
import { EventType } from 'types';
import { ButtonContainer } from '../styles';
import { AddEvent } from '../AddEvent';
import { Reminder } from '../Reminder';

export interface EventProps {
  data?: EventType;
  callback?: (item: EventType) => void;
}

export const Event: FC<EventProps> = ({ data, callback }) => {
  const { hideModal } = useModal();

  if (!callback) {
    hideModal();
    return <></>;
  }

  const methods = useForm<EventType>({
    mode: 'onChange',
    resolver: yupResolver(eventSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    hideModal();
  };

  const handleAdd = (data: EventType) => {
    callback(data);
    hideModal();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleAdd)}>
        <AddEvent eventData={data} errors={errors} />
        <Reminder eventData={data} errors={errors} />
        <ButtonContainer>
          <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
          <Button text="button.save" variant="primary" onSubmit={() => handleAdd} />
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};
