const AppConfig = {
  LANGUAGES: ['pl', 'en'],
  NOTIFICATION_TIME: 4000,
  DEFAULT_LANG: 'pl',
  DATE_FORMAT: 'dd.MM.yyy',
  DATE_FORMAT_INPUT: 'yyyy-MM-dd',
  TIME_FORMAT: 'HH:mm',
  DAY_FORMAT: 'dd.MM',
  LONG_DATE_FORMAT: 'iiii, d MMMMMM',
  MONTH_FORMAT: 'LLLL yyyy',
  DAY_MONTH_YEAR: 'dd LLLL yyyy',
};

export default AppConfig;
