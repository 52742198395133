import styled, { css } from 'styled-components';
import { ButtonType } from 'types';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  variant: ButtonType;
  positionLeft?: boolean;
  margin?: string;
}

export const BasicButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  margin: ${({ theme: { spaces }, margin }) => spaces[margin!]};
  align-items: center;
  gap: ${({ theme }) => theme.spaces.md};
  padding: ${({ theme }) => theme.spaces.defaultButtonPadding};
  height: ${({ theme }) => theme.sizes.inputHeight};
  border-radius: ${({ theme }) => theme.radius.sm};
  font-size: ${({ theme }) => theme.fontSizes.md};
  cursor: pointer;
  ${({ variant, positionLeft }) => {
    switch (variant) {
      case 'primary':
        return css`
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.light};
          transition: 0.4s;
          &:hover {
            background-color: ${({ theme }) => theme.colors.accent};
          }
        `;
      case 'secondary':
        return css`
          background-color: ${({ theme }) => theme.colors.light};
          color: ${({ theme }) => theme.colors.primary};
          border: 1px solid ${({ theme }) => theme.colors.primary};
          margin-right: 0px;
          margin-left: ${() => (positionLeft ? '0px' : 'auto')};
          padding: ${({ theme }) => theme.spaces.secondaryButtonPadding};
          ${ltMedia('sm')} {
            width: 100%;
          }
        `;
      case 'primaryLight':
        return css`
          color: ${({ theme }) => theme.colors.accent};
          background-color: ${({ theme }) => theme.colors.light};
          padding: 0px;
        `;
      case 'secondaryLight':
        return css`
          background-color: ${({ theme }) => theme.colors.light};
          color: ${({ theme }) => theme.colors.error};
          padding: 0px;
        `;
      case 'primaryFixed':
        return css`
          margin-right: 0px;
          margin-left: auto;
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.light};
          transition: 0.4s;
          &:hover {
            background-color: ${({ theme }) => theme.colors.accent};
          }
          ${ltMedia('sm')} {
            right: ${({ theme }) => theme.spaces.lg};
            left: ${({ theme }) => theme.spaces.lg};
          }
        `;
      default:
        css`
          background-color: ${({ theme }) => theme.colors.primary};
          color: ${({ theme }) => theme.colors.light};
        `;
    }
  }};

  ${ltMedia('sm')} {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    height: ${({ theme }) => theme.sizes.inputMobileHeight};
  }
`;
