import {
  ArchiveOrRestoreUser,
  ArchivingRes,
  BasePaginationRes,
  BaseUserDataToList,
  UserBasicData,
} from 'work-planner-backend/types';
import { useContext } from 'react';
import { ModalType, UserBasicInfoListFilters } from 'types';
import { __ } from 'services/translation';
import { format } from 'date-fns';
import { BasePaginationContext, UserBasicListFiltersContext } from 'context';
import AppConfig from 'config/app';
import { useApi } from '../useApi';
import { prepareApiParamsObj } from 'utils/queryParams';
import { useModal } from '../useModal';

interface UseUserList {
  getUserBasicInfoList: () => Promise<void>;
  applyUsersFilters: (usersFilters?: UserBasicInfoListFilters) => Promise<void>;
  blockOrRestoreUser: (user: BaseUserDataToList) => Promise<void>;
  archiveUser: (user: BaseUserDataToList) => Promise<void>;
}

export const useUserListBasic = (): UseUserList => {
  const { apiGet, apiPatch } = useApi();
  const { showModal, hideModal } = useModal();
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const {
    setData: setUsersBasicInfo,
    setFilters,
    filters,
  } = useContext(UserBasicListFiltersContext);

  const getUserBasicInfoList = async () => {
    const result = await apiGet<BasePaginationRes<BaseUserDataToList>>({
      url: '/users',
      options: { params: prepareApiParamsObj({ ...filters, ...pagination }) },
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setUsersBasicInfo(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const applyUsersFilters = async (usersFilters?: UserBasicInfoListFilters) => {
    const preparedFiltersData = {
      search: usersFilters?.search,
      status: usersFilters?.status?.value || '',
      experienceLevel: usersFilters?.experienceLevel?.value || '',
      positionId: usersFilters?.positionId?.value || '',
    };

    const result = await apiGet<BasePaginationRes<BaseUserDataToList>>({
      url: '/users',
      options: { params: prepareApiParamsObj({ ...preparedFiltersData, ...pagination }) },
    });

    setFilters(prepareApiParamsObj(preparedFiltersData));

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setUsersBasicInfo(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const blockOrRestoreUser = async (user: BaseUserDataToList) => {
    const action = user.isManuallyBlocked ? 'restore' : 'block';
    const result = await apiPatch<UserBasicData, null>({
      url: `users/manually-blocking/${action}/${user.id}`,
    });

    if (result) {
      const { isManuallyBlocked, id } = result;
      setUsersBasicInfo((prev) =>
        prev.map((userArray) =>
          userArray.id === id ? { ...userArray, isManuallyBlocked } : userArray,
        ),
      );
    }
  };

  const archiveCallback = async (date: Date, userId: string, remove?: boolean) => {
    let result;

    if (remove) {
      result = await apiPatch<ArchivingRes, null>({
        url: `users/archiving/remove-future-archiving/${userId}`,
      });
    } else {
      result = await apiPatch<ArchivingRes, ArchiveOrRestoreUser>({
        url: `users/archiving/archive/${userId}`,
        body: { date: format(new Date(date), AppConfig.DATE_FORMAT_INPUT) as unknown as Date },
      });
    }

    if (result) {
      const { isArchived } = result;
      setUsersBasicInfo((prev) =>
        prev.map((userArray) =>
          userArray.id === userId ? { ...userArray, isArchived } : userArray,
        ),
      );
      hideModal();
    }
  };

  const archiveUser = async (user: BaseUserDataToList) => {
    const userArchives = await apiGet<ArchivingRes>({ url: `users/archiving/${user.id}` });

    if (userArchives) {
      showModal({
        header: __('heading.archiving'),
        modalType: ModalType.USER_ARCHIVING,
        data: userArchives,
        callback: (date: Date, remove?: boolean) => archiveCallback(date, user.id, remove),
      });
    }
  };

  return { getUserBasicInfoList, applyUsersFilters, blockOrRestoreUser, archiveUser };
};
