import styled, { css } from 'styled-components';
import Select from 'react-select';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  error?: boolean;
  focus?: boolean;
  selectedValue?: boolean;
  isMulti?: boolean;
}

export const SelectComponent = styled(Select)<Props>`
  margin-bottom: 8px;
  .select__placeholder,
  .select__input-container,
  .select__single-value {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
    ${ltMedia('sm')} {
      font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
    }
  }

  .select__menu {
    z-index: 3;
    & div {
      font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
    }
  }

  .select__value-container {
    padding: ${({ theme: { spaces } }) => spaces.mdHorizontalPadding};
  }

  .select__indicators {
    cursor: pointer;
  }

  .select__control--is-focused {
    & svg {
      fill: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
    }
  }

  .select__control {
    background-color: ${({ theme: { colors } }) => colors.light};
    min-height: ${({ theme: { sizes } }) => sizes.inputHeight};
    border-radius: ${({ theme: { radius } }) => radius.sm};
    border: 1px solid ${({ theme: { colors }, error }) => (error ? colors.error : colors.border)};
    box-shadow: none;
    z-index: 1;
    cursor: text;
    & svg {
      margin: ${({ theme: { spaces } }) => spaces.sm};
      fill: ${({ theme: { colors }, error }) => error && colors.error};
    }

    :hover {
      border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.border)};
    }
    :focus-within {
      border-color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
    }
    ${ltMedia('sm')} {
      min-height: ${({ theme: { sizes } }) => sizes.inputMobileHeight};
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__option {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
    color: ${({ theme: { colors } }) => colors.dark};
    background-color: transparent;
    cursor: pointer;
    :hover {
      color: ${({ theme: { colors } }) => colors.primary};
    }
    :focus,
    :active {
      background-color: transparent;
    }
    ${ltMedia('sm')} {
      font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
    }
  }
  .select__value-container--is-multi {
    margin: ${({ theme: { spaces } }) => spaces.sm} 0px;
  }
  .select__multi-value {
    flex-direction: row-reverse;
    font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
    color: ${({ theme: { colors } }) => colors.dark};
    background-color: ${({ theme: { colors } }) => colors.item};
    border-radius: ${({ theme: { radius } }) => radius.sm};
    padding: ${({ theme: { spaces } }) => spaces.sm};
    cursor: pointer;
    &__label {
      font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
      padding: 0px;
    }
    &__remove {
      :hover {
        background-color: transparent;
      }
      svg {
        fill: ${({ theme: { colors } }) => colors.dark};
        :hover {
          fill: ${({ theme: { colors } }) => colors.error};
        }
        margin: 0px ${({ theme: { spaces } }) => spaces.xs} 0px 0px;
      }
    }
  }
`;
export const SelectLabel = styled.label<Props>`
  margin-bottom: -16px;
  position: relative;
  display: block;
  width: fit-content;
  color: ${({ theme: { colors }, error, focus }) =>
    error ? colors.error : focus ? colors.primary : colors.dark};
  transform: translate(${({ theme: { spaces } }) => spaces.desktopSelectDisplacement});
  z-index: 2;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  padding: 0px ${({ theme: { spaces } }) => spaces.xs};
  background-color: white;
`;
