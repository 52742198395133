import styled, { css } from 'styled-components';

interface ModalProps {
  visible: boolean;
}

export const ModalBackground = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.modalBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  animation-name: showModal;
  animation-duration: 0.15s;
  transition: opacity 250ms ease-in-out;
  opacity: 1;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `}
`;

export const StyledBody = styled.div<ModalProps>`
  position: relative;
  z-index: 1000;
  max-height: 90vh;
  min-width: ${({ theme }) => theme.sizes.modalMinWidth};
  max-width: 80vw;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.radius.md};
  background-color: ${({ theme }) => theme.colors.light};

  padding: ${({ theme }) => theme.spaces.modalPadding};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.md};

  transform: scale(0.9);
  transition: transform 1.5s ease-in-out;

  ${(props) =>
    props.visible &&
    css`
      transform: scale(1);
    `}
`;

export const StyledIcon = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spaces.xl};
  top: ${({ theme }) => theme.spaces.xl};

  cursor: pointer;
`;
