import { UserFullData } from '../users';

export enum NotificationType {
  System = 'SYSTEM',
  BillingPeriodReq = 'BILLING_PERIOD_REQ',
}

export interface NotificationBaseData {
  id: string;
  type: NotificationType;
  title: string;
  description: string;
  link: string;
  createdAt: Date;
}

export interface NotificationFullData extends NotificationBaseData {
  creator: UserFullData;
  recipients: NotificationRecipientFullData[];
  doneBy: UserFullData;
}

export type DescriptionDataPathsAndArgs = {
  titlePath: string;
  descriptionPath: string;
  titleArgs?: NotificationDescriptionArgs;
  descriptionArgs?: NotificationDescriptionArgs;
  link?: string;
};

export type NotificationDescriptionArgs = Record<Args, string>;

type Args = 'admin' | 'employee';

export interface ListMyActiveNotificationsRes {
  id: string;
  title: string;
  description: string;
  link: string;
  createdAt: string;
  displayed: boolean;
}

export interface NotificationRecipientBaseData {
  id: string;
  displayed: boolean;
}

export interface NotificationRecipientFullData extends NotificationRecipientBaseData {
  notification: NotificationFullData;
}
