import { ProjectsFullData } from './projects';

export enum ProjectsDocumentationsType {
  Text = 'TEXT',
  Video = 'VIDEO',
}

export interface ProjectsDocumentationsFullData {
  id: string;
  description: string;
  link: string;
  type: ProjectsDocumentationsType;
  project: ProjectsFullData;
}

export type ProjectsDocumentationsBaseData = Omit<ProjectsDocumentationsFullData, 'project'>;

export type CreateDocument = Omit<ProjectsDocumentationsFullData, 'id' | 'project'>;
