import { __ } from 'services/translation';
import * as yup from 'yup';
import { object as yupObject } from 'yup';
import { checkPositiveNumber } from '../yupValidators';

export const firstStep = yupObject().shape({
  projectName: yup.string().required('validation.fieldIsRequired'),
  projectStatus: yup.object().required('validation.fieldIsRequired'),
  startDate: yup.string().notRequired(),
  endDate: yup.string().notRequired(),
  sprintFirstDay: yup.string().notRequired(),
  projectHours: checkPositiveNumber(),
  budget: checkPositiveNumber().required('validation.fieldIsRequired'),
  budgetCurrency: yup.object(),
  limitProfitCurrency: checkPositiveNumber(),
  technologies: yup.array(),
  description: yup.string(),
  color: yup.object(),
  pm: yup.object(),
});

export const secondStep = yupObject().shape({
  name: yup.string().required('validation.fieldIsRequired'),
});

export const thirdStepFilters = yupObject().shape({
  startDate: yup.string().notRequired(),
  endDate: yup.string().notRequired(),
  search: yup.string().notRequired(),
  experienceLevel: yup.object().notRequired(),
  positionId: yup.object().notRequired(),
});

export const thirdStepReservation = yupObject().shape({
  startDate: yup.string().required('validation.fieldIsRequired'),
  endDate: yup.string().required('validation.fieldIsRequired'),
  hoursPerDay: yup.string().required('validation.fieldIsRequired'),
});
