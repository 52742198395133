import {
  CreateBudgetDetails,
  CreateClient,
  CreateDocument,
  CreateEvent,
  CreateProject,
  CreateProjectPayload,
  CurrencyCode,
  ProjectClientRes,
  ProjectFullDataWithoutReservationsAndBudget,
  ProjectFullDataWithReservationsShortcut,
  ProjectRes,
  ProjectsBudgetDetailsBaseData,
  ProjectsDocumentationsType,
  ProjectsStatus,
  SkillFullData,
} from 'work-planner-backend/types';
import { format } from 'date-fns';
import { useApi } from 'hooks';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addProjectRoutes } from 'router';
import { __ } from 'services/translation';
import { convertFromHEXToHSL, convertFromHEXToRGB } from 'utils/colorParser';
import AppConfig from 'config/app';
import { ProgressBarContext } from 'context';
import {
  AddProjectClient,
  CreateProjectData,
  ProjectConfigurationFirstStep,
  ProjectSummary,
} from 'types';
import { getSelectOptionsFromEnum } from 'utils';

interface UseAddEditProject {
  getSummaryData: (projectId: string) => Promise<void>;
  summaryData: ProjectSummary | null;
  createProject: (data: CreateProjectData) => Promise<void>;
  addProjectClient: (data: AddProjectClient) => Promise<void>;
  getClient: () => Promise<void>;
  client: ProjectClientRes | null;
  nextStep: () => void;
  previousStep: () => void;
  getProject: () => void;
  projectInfo: ProjectConfigurationFirstStep | null;
}

export const useAddEditProject = (): UseAddEditProject => {
  const { apiGet, apiPost, apiPatch } = useApi();
  const { projectId } = useParams();
  const { nextStep, previousStep } = useContext(ProgressBarContext);
  const [summaryData, setSummaryData] = useState<ProjectSummary | null>(null);
  const [client, setClient] = useState<ProjectClientRes | null>(null);
  const [projectInfo, serProjectInfo] = useState<ProjectConfigurationFirstStep | null>(null);

  const createProject = async ({
    body,
    projectDocuments,
    projectEvents,
    projectVideos,
  }: CreateProjectData) => {
    const {
      projectName,
      projectStatus,
      sprintFirstDay,
      projectHours,
      budget,
      limitProfit,
      description,
      color,
      startDate,
      endDate,
      budgetCurrency,
      technologies,
      budgetType,
      pm,
    } = body;

    const defaultCurrency = getSelectOptionsFromEnum(CurrencyCode)[0];

    const preparedTechnologies: SkillFullData[] = technologies.map(({ value, label }) => ({
      id: value,
      name: label,
    }));

    const preparedVideos = projectVideos.map(({ firstColumn, secondColumn }) => ({
      link: secondColumn,
      description: firstColumn,
      type: ProjectsDocumentationsType.Video,
    }));

    const preparedDocuments = projectDocuments.map(({ firstColumn, secondColumn }) => ({
      link: secondColumn,
      description: firstColumn,
      type: ProjectsDocumentationsType.Text,
    }));

    const preparedEvents: CreateEvent[] = projectEvents.map(
      ({ title, eventDescription, date, reminderDate, notify }) => ({
        title: title,
        description: eventDescription,
        startDate: format(new Date(date), AppConfig.DATE_FORMAT_INPUT),
        reminderDate: format(new Date(reminderDate), AppConfig.DATE_FORMAT_INPUT),
        notificationType: notify,
      }),
    );

    const documentations: CreateDocument[] = [...preparedVideos, ...preparedDocuments];

    const project: CreateProject = {
      name: projectName,
      status: projectStatus.value as ProjectsStatus,
      mainTechnologies: preparedTechnologies,
      description,
      color: color.hex,
      startDate: format(new Date(startDate), AppConfig.DATE_FORMAT_INPUT),
      endDate: format(new Date(endDate), AppConfig.DATE_FORMAT_INPUT),
      sprintStartDate: format(new Date(sprintFirstDay), AppConfig.DATE_FORMAT_INPUT),
    };

    const budgetDetails: CreateBudgetDetails = {
      budgetType,
      hours: projectHours,
      originalAmount: budget,
      originalAmountCurrencyCode: budgetCurrency.value as CurrencyCode,
      originalMinimalProfit: Number(limitProfit),
      originalMinimalProfitCurrencyCode: defaultCurrency.value as CurrencyCode,
    };

    const finalBody: CreateProjectPayload = {
      project,
      budgetDetails,
      documentations,
      events: preparedEvents,
      pmId: pm.value,
    };

    let result;

    if (projectId) {
      result = await apiPatch<ProjectRes, CreateProjectPayload>({
        url: `projects/${projectId}`,
        body: finalBody,
      });
    } else {
      result = await apiPost<ProjectRes, CreateProjectPayload>({
        url: 'projects',
        body: finalBody,
      });
    }

    if (result) {
      if (projectId) {
        nextStep();
      } else {
        nextStep(`${addProjectRoutes.state}/${result.id}/principals`);
      }
    }
  };

  const getProject = async () => {
    const project = await apiGet<ProjectFullDataWithoutReservationsAndBudget>({
      url: `projects/${projectId}`,
    });

    const projectBudget = await apiGet<ProjectsBudgetDetailsBaseData>({
      url: `/projects/budget-details/${projectId}`,
    });

    if (project && projectBudget) {
      const preparedData: ProjectConfigurationFirstStep = {
        projectName: project.name,
        projectStatus: { label: __(`enum.${project.status}`.toLowerCase()), value: project.status },
        startDate: new Date(project.startDate),
        endDate: new Date(project.endDate),
        sprintFirstDay: new Date(project.sprintStartDate),
        projectHours: projectBudget.hours,
        budget: projectBudget.originalAmount,

        budgetCurrency: {
          label: projectBudget.originalAmountCurrencyCode,
          value: projectBudget.originalAmountCurrencyCode,
        },
        limitProfit: projectBudget.originalMinimalProfit,
        limitProfitCurrency: {
          label: __(`enum.${projectBudget.originalMinimalProfitCurrencyCode}`.toLowerCase()),
          value: projectBudget.originalMinimalProfitCurrencyCode,
        },
        technologies: project.mainTechnologies.map(({ id, name }) => ({ label: name, value: id })),
        description: project.description,
        color: {
          hex: project.color,
          rgb: convertFromHEXToRGB(project.color),
          hsl: convertFromHEXToHSL(project.color),
        },
        documentsTable: project.documentations
          .filter(({ type }) => type === ProjectsDocumentationsType.Text)
          .map(({ description, link }) => ({
            firstColumn: description,
            secondColumn: link,
          })),
        videosTable: project.documentations
          .filter(({ type }) => type === ProjectsDocumentationsType.Video)
          .map(({ description, link }) => ({
            firstColumn: description,
            secondColumn: link,
          })),
        budgetType: projectBudget.budgetType,
        pm: { label: `${project.pm.firstName} ${project.pm.lastName}`, value: project.pm.id },
        events: project.events.map(
          ({ id, description, title, startDate, reminderDate, notificationType }) => ({
            id,
            title,
            date: startDate as string,
            eventDescription: description,
            reminderDate: reminderDate as string,
            notify: notificationType,
          }),
        ),
      };
      serProjectInfo(preparedData);
    }
  };

  const addProjectClient = async ({ body, contactPerson }: AddProjectClient) => {
    const { name } = body;
    const preparedBody: CreateClient = {
      name,
      agents: contactPerson,
    };

    let result;

    if (client) {
      result = await apiPatch({
        url: `projects/clients/${projectId}/${client.id}`,
        body: preparedBody,
      });
    } else {
      result = await apiPost({
        url: `projects/clients/${projectId}`,
        body: preparedBody,
      });
    }

    if (result) {
      nextStep();
    }
  };

  const getClient = async () => {
    const result = await apiGet<ProjectFullDataWithoutReservationsAndBudget>({
      url: `projects/${projectId}`,
    });

    if (result && result.client?.id) {
      const res = await apiGet<ProjectClientRes>({
        url: `projects/clients/agents/${result.client.id}`,
      });
      if (res) {
        setClient(res);
      }
    }
  };

  const getSummaryData = async (projectId: string) => {
    const result = await apiGet<ProjectFullDataWithReservationsShortcut>({
      url: `projects/summary/${projectId}`,
    });

    if (result) {
      const { reservations, documentations: links, events, ...rest } = result;
      const summary: ProjectSummary = {
        links,
        events,
        reservations,
        project: rest,
      };
      setSummaryData(summary);
    }
  };

  return {
    getSummaryData,
    summaryData,
    createProject,
    addProjectClient,
    getClient: getClient,
    client,
    nextStep,
    previousStep,
    getProject,
    projectInfo,
  };
};
