import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { __ } from 'services/translation';
import { ErrorText } from '../styles';
import { TextareaComponent, TextareaContainer, TextareaLabel } from './styles';

interface Props {
  name: string;
  labelText?: string;
  defaultValue?: string | number;
  placeholder?: string;
  errorText?: string;
}

export const Textarea: FC<Props> = ({ name, labelText, placeholder, errorText }) => {
  const { register } = useFormContext();
  return (
    <TextareaContainer>
      <TextareaComponent
        id={name}
        placeholder={placeholder && __(placeholder)}
        {...register(name)}
      />
      <TextareaLabel htmlFor={name} error={Boolean(errorText)}>
        {labelText && __(labelText)}
      </TextareaLabel>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </TextareaContainer>
  );
};
