export const colors = {
  background: '#F6F7F9',
  authBackground: '#F4F7FD',
  error: '#D70F0F',
  success: '#1A970F',
  newSuccess: '#67C96F',
  light: '#FFF',
  dark: '#1C2533',
  border: '#CAD0DE',
  primary: '#2F2FF2',
  secondary: '#49536B',
  accent: '#090990',
  icon: '#49536B',
  modalBackground: '#0000003F',
  modalShadow: '0 5px 20px -5px #00000026',
  defaultFont: '#838795',
  defaultDateRange: '#B9CBFB',
  defaultShadow: '2px 0px 8px #0000000D',
  boxShadow: '0px 3px 8px #0000000D',
  item: '#EAECF1',
  circle: '#67C96F',
  circleLight: '#E8F8E7',
  archived: 'red',
  blocked: 'orange',
  active: 'green',
  low: 'red',
  medium: 'orange',
  high: 'green',
};

export const ColorsInColorPicker = [
  '#1E1E1E',
  '#3A3A3A',
  '#636466',
  '#C9C9C9',
  '#725849',
  '#A17D66',
  '#CEBDAB',
  '#F2E4D7',
  '#026876',
  '#009488',
  '#00AFA9',
  '#A9DCC5',
  '#0055AE',
  '#017DB3',
  '#09B0F1',
  '#86D2E8',
  '#622858',
  '#8C2679',
  '#7B55A0',
  '#A29EC5',
  '#DF1F5A',
  '#FE617C',
  '#FC96AB',
  '#FCC4D1',
  '#CE1131',
  '#DA4643',
  '#F07C7A',
  '#FBB5AE',
  '#F46C38',
  '#FF914D',
  '#F8AF7C',
  '#FDC369',
  '#DEA000',
  '#F6C826',
  '#F5EA1A',
  '#FFEDB4',
  '#767E41',
  '#97A842',
  '#BFD658',
  '#DCE579',
  '#4E6A5B',
  '#006851',
  '#0CA85C',
  '#CDDCB6',
];
