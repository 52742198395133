export enum ApiResult {
  Error,
  Ok,
}

export interface NoPayloadResponse {
  result: number;
}

export type OkResponsePayload<T> = {
  payload: T;
} & NoPayloadResponse;

export type ErrorResponsePayload = {
  error_code: number;
  error_login_id: string;
} & NoPayloadResponse;

export type UnauthorizedResponse = {
  statusCode: number;
  message: string;
};

export type BadRequestDtoResponse = {
  error: string;
  message: string[];
  statusCode: number;
};

export type ForbiddenResource = {
  statusCode: number;
  message: string;
  error: string;
};
