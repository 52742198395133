import { ConfirmProps } from 'components/modals/Confirm';
import { EventProps } from 'components/modals/EventModal/AddEventModal';
import { UpdateLinkTableProps } from 'components/modals/LinkTableEditModal';
import { AddContactPersonProps } from 'components/modals/AddContactPerson';
import { SkillEditProps } from 'components/modals/SkillEditModal';
import { AddLinkTableProps } from 'components/modals/LinkTableAddModal';
import { UserAvailableProps } from 'components/modals/UserAvailableModal';
import { UserArchivingProps } from 'components/modals/UserArchiving';
import { PositionDictionaryEditProps } from 'components/modals/DictionaryModal/PositionEdit';
import { SkillDictionaryEditProps } from 'components/modals/DictionaryModal/SkillEdit';

export enum ModalType {
  CONFIRM = 'confirm',
  EMPTY = 'empty',
  EVENT = 'event',
  UPDATE_LINK = 'tableLinkEdit',
  ADD_LINK = 'tableLinkAdd',
  EDIT_SKILL = 'editSkill',
  ADD_CONTACT_PERSON = 'addContactPerson',
  USER_AVAILABLE = 'userAvailable',
  USER_ARCHIVING = 'userArchiving',
  EDIT_DICTIONARY_POSITION = 'editDictionaryPosition',
  EDIT_DICTIONARY_SKILL = 'editDictionarySkill',
}

export type ModalData =
  | ({
      modalType?: ModalType.CONFIRM;
    } & ConfirmProps)
  | ({
      modalType?: ModalType.EVENT;
    } & EventProps)
  | ({
      modalType?: ModalType.UPDATE_LINK;
    } & UpdateLinkTableProps)
  | ({
      modalType?: ModalType.ADD_LINK;
    } & AddLinkTableProps)
  | ({
      modalType?: ModalType.EDIT_SKILL;
    } & SkillEditProps)
  | ({
      modalType?: ModalType.ADD_CONTACT_PERSON;
    } & AddContactPersonProps)
  | ({
      modalType?: ModalType.USER_AVAILABLE;
    } & UserAvailableProps)
  | ({
      modalType?: ModalType.USER_ARCHIVING;
    } & UserArchivingProps)
  | ({
      modalType?: ModalType.EDIT_DICTIONARY_POSITION;
    } & PositionDictionaryEditProps)
  | ({
      modalType?: ModalType.EDIT_DICTIONARY_SKILL;
    } & SkillDictionaryEditProps);
