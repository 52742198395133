import { ReactNode } from 'react';
import { FlexComponent } from './styles';

interface Props {
  children: ReactNode;
  columnDirection?: boolean;
  width?: string;
  space?: string;
  gap?: string;
  align?: string;
}

export const Flex = ({ children, columnDirection, width, space, align, gap }: Props) => (
  <FlexComponent
    columnDirection={columnDirection}
    width={width}
    space={space}
    align={align}
    gap={gap}
  >
    {children}
  </FlexComponent>
);
