import Cookies from 'universal-cookie';
import { i18n } from 'services/translation';
import CONFIG from 'config/app';

export const setCurrentLanguage = (lang: string) => {
  if (i18n.currentLang === lang) {
    return;
  }

  const cookies = new Cookies();
  cookies.set('lang', lang);
  window.location.reload();
};

export const getUsingLang = () => i18n.currentLang ?? CONFIG.DEFAULT_LANG;

export const getCurrentLanguage = () => {
  const cookies = new Cookies();
  const localLanguage = cookies.get('lang');
  if (localLanguage) {
    return localLanguage;
  }

  const clientLanguage = window.navigator.language.slice(0, 2).toLowerCase();
  if (CONFIG.LANGUAGES.includes(clientLanguage)) {
    return clientLanguage;
  }

  return CONFIG.DEFAULT_LANG;
};

export const getLangName = (lang: string) => {
  switch (lang) {
    case 'en':
      return 'English (US)';
    case 'pl':
    default:
      return 'Polski (PL)';
  }
};

export const getLangForBe = () => getCurrentLanguage().toUpperCase();
