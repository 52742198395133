import { useAppSelector, useModal } from 'hooks';
import { Icon } from 'components/common';
import { ModalBackground, StyledBody, StyledIcon } from './styles';
import { Header } from './Header';
import { Content } from './Content';

const Modal = () => {
  const { hideModal } = useModal();
  const { isOpened, header } = useAppSelector((state) => state.modal);
  if (!isOpened) {
    return null;
  }

  const handleClose = () => {
    hideModal();
  };

  const showHeader = () => {
    if (!header) return <></>;

    return (
      <>
        <StyledIcon>
          <Icon icon="close" iconColor="dark" onClick={handleClose} />
        </StyledIcon>
        <Header text={header} />
      </>
    );
  };

  return (
    <ModalBackground visible={isOpened} id="modal">
      <StyledBody visible={isOpened}>
        {showHeader()}
        <Content />
      </StyledBody>
    </ModalBackground>
  );
};

export { Modal };
