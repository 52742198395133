import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { __ } from 'services/translation';
import { ProgressStepType } from 'types';
import { ProgressBarBox, ProgressBarLine, ProgressHelpText, ProgressStep } from './styles';

interface Props {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  stepsArray: ProgressStepType[];
}

export const ProgressBar: FC<Props> = ({ step, setStep, stepsArray }) => (
  <ProgressBarBox>
    {stepsArray.map((item) => {
      const { progressStep, helpText } = item;
      return (
        <Fragment key={progressStep}>
          <ProgressBarLine active={!!(progressStep <= step)}>
            <ProgressHelpText>{__(helpText)}</ProgressHelpText>
          </ProgressBarLine>
          <ProgressStep
            active={!!(progressStep < step)}
            actual={!!(progressStep < step)}
            onClick={() => progressStep < step && setStep(progressStep)}
          />
        </Fragment>
      );
    })}
  </ProgressBarBox>
);
