import { Form } from 'components/common';
import styled from 'styled-components';

export const AddLinkForm = styled(Form)`
  max-width: ${({ theme }) => theme.sizes.modalMinWidth};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.lg};
  margin-top: ${({ theme }) => theme.spaces.lg};

  & button {
    width: 25%;
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.lg};
`;
