import styled from 'styled-components';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  size?: string;
  isBold?: boolean;
  margin?: string;
}

const BasicText = styled.p<Props>`
  color: ${({ theme: { colors } }) => colors.dark};
  font-size: ${({ theme: { fontSizes }, size }) => (size ? fontSizes[size] : fontSizes.xs)};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  margin: ${({ theme: { spaces }, margin }) => (margin ? spaces[margin] : '0px')};
  ${ltMedia('sm')} {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xxs};
  }
`;

const SpanText = styled.span<Props>`
  color: ${({ theme: { colors } }) => colors.dark};
  font-size: ${({ theme: { fontSizes }, size }) => (size ? fontSizes[size] : fontSizes.xs)};
  font-weight: ${({ isBold }) => (isBold ? 'bold' : 'normal')};
  margin: ${({ theme: { spaces }, margin }) => (margin ? spaces[margin] : '0px')};
  ${ltMedia('sm')} {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.xxs};
  }
`;

export { BasicText, SpanText };
