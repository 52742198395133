import { BasePaginationRes, ProjectListRes } from 'work-planner-backend/types';
import { useContext } from 'react';
import { ProjectListFilters } from 'types';
import { __ } from 'services/translation';
import { prepareApiParamsObj } from 'utils/queryParams';
import { BasePaginationContext, ProjectsFiltersContext } from 'context';
import { useApi } from '../useApi';

interface UseProjectList {
  getProjectsList: () => Promise<void>;
  applyProjectFilters: (usersFilters?: ProjectListFilters) => Promise<void>;
}

export const useProjectList = (): UseProjectList => {
  const { apiGet } = useApi();
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const { setData: setProjects, setFilters, filters } = useContext(ProjectsFiltersContext);

  const getProjectsList = async () => {
    const result = await apiGet<BasePaginationRes<ProjectListRes>>({
      url: '/projects',
      options: { params: prepareApiParamsObj({ ...filters, ...pagination }) },
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setProjects(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const applyProjectFilters = async (usersFilters?: ProjectListFilters) => {
    const preparedFiltersData = {
      search: usersFilters?.search,
      projectStatus: usersFilters?.projectStatus?.value || '',
      projectReservationsTimeRange: usersFilters?.projectReservationsTimeRange?.value || '',
    };

    const result = await apiGet<BasePaginationRes<ProjectListRes>>({
      url: '/projects',
      options: { params: prepareApiParamsObj({ ...preparedFiltersData, ...pagination }) },
    });

    setFilters(prepareApiParamsObj(preparedFiltersData));

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setProjects(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  return { getProjectsList, applyProjectFilters };
};
