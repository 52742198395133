import { CreateDocument, ProjectsDocumentationsBaseData } from './projects-documentations';
import { CreateBudgetDetails, ProjectsBudgetDetailsBaseData } from './projects-budget-details';
import { SkillFullData } from '../skills';
import { CreateEvent, ProjectsEventsBaseData } from './projects-events';
import { ProjectsClientsDataWithAgentsOptionally } from './projects-clients';
import {
  ReservationBaseData,
  ReservationInvolvement,
  ReservationWithEmployeeShortcut,
} from './reservations';
import { BasePaginationRes, BasePaginationWithSearch } from '../pagination';
import { PmDiminishedData, UserBasicData } from '../users';
import { ReplacementDatesWithEmployeeFullName } from './replacements';

export enum ProjectsStatus {
  Potential = 'POTENTIAL',
  Future = 'FUTURE',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
}

export interface ProjectBaseData {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  sprintStartDate: string;
  description: string;
  color: string;
  status: ProjectsStatus;
}

export interface ProjectBaseDataWithTechnologies extends ProjectBaseData {
  mainTechnologies: SkillFullData[];
}

export interface ProjectsFullData extends ProjectBaseData {
  client: ProjectsClientsDataWithAgentsOptionally | null;
  mainTechnologies: SkillFullData[];
  budgetDetails: ProjectsBudgetDetailsBaseData;
  documentations: ProjectsDocumentationsBaseData[];
  reservation: ReservationBaseData[];
  events: ProjectsEventsBaseData[];
  pm: UserBasicData;
}

export type ProjectsDiminishedData = Pick<ProjectsFullData, 'id' | 'name'>;

export type ProjectDiminishedDataWithPm = ProjectsDiminishedData & { pm: string };

export type CalendarInvolvementRes =
  BasePaginationRes<ProjectDiminishedDateWithPmAndInvolvementReservations> & {
    datesRange: { start: string; end: string };
  };

export type ProjectDiminishedDateWithPmAndInvolvementReservations = {
  project: ProjectDiminishedDataWithPm;
  reservations: ReservationInvolvement[];
};

export type ProjectDiminishedDateWithPmAndInvolvementReservation = {
  project: ProjectDiminishedDataWithPm;
  reservation: ReservationInvolvement;
};

export type CreateProject = {
  name: string;
  status: ProjectsStatus;
  mainTechnologies: SkillFullData[];
  description: string;
  color: string;
  startDate: Date | string;
  endDate: Date | string;
  sprintStartDate: Date | string;
};

export type ProjectRes = CreateProject & { id: string };

export type ProjectBaseDataWithPm = CreateProject & { id: string; pm: PmDiminishedData };

export interface ProjectListRes {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  status: ProjectsStatus;
  clientName: string | null;
  projectManager: string | null;
  replacement: ReplacementDatesWithEmployeeFullName | null;
}

export interface CreateProjectPayload {
  project: CreateProject;
  budgetDetails: CreateBudgetDetails;
  documentations: CreateDocument[];
  events: CreateEvent[];
  pmId?: string;
}

export type ProjectFullDataWithoutReservationsAndBudget = Omit<
  ProjectsFullData,
  'reservation' | 'budgetDetails'
>;

export type ProjectFullDataWithReservationsShortcut =
  ProjectFullDataWithoutReservationsAndBudget & { reservations: ReservationWithEmployeeShortcut[] };

export enum ProjectReservationsTimeRange {
  CurrentAndFuture = 'CURRENT_AND_FUTURE',
  PastOnly = 'PAST_ONLY',
  All = 'ALL',
}

export interface ProjectsPaginatedList extends BasePaginationWithSearch {
  projectReservationsTimeRange?: ProjectReservationsTimeRange;
  projectStatus?: ProjectsStatus;
}
