import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TranslateText, Icon } from 'components/common';
import { BasicLink } from './styles';

interface Props {
  text: string;
  redirect: string;
  position?: string;
  margin?: string;
  noTranslate?: boolean;
  icon?: string;
}

export const Link: FC<Props> = ({ text, redirect, position, margin, noTranslate, icon }) => (
  <BasicLink position={position} margin={margin}>
    <RouterLink to={redirect}>
      {icon && <Icon icon={icon} />}
      <TranslateText text={text} noTranslate={noTranslate} />
    </RouterLink>
  </BasicLink>
);
