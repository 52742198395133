import { Icon } from 'components/common/Icon';
import { FC, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { __ } from 'services/translation';
import { ErrorText, HelpText } from '../styles';
import { InputComponent, InputIcon, InputLabel, InputWrapper } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelText?: string;
  placeholder?: string;
  errorText?: string;
  helpText?: string;
  placeholderRight?: boolean;
  alignEnd?: boolean;
  noTranslateHelpText?: boolean;
  icon?: string;
  iconOnClick?: () => void;
  setValue?: (item: string) => void;
}

export const Input: FC<Props> = ({
  name,
  type = 'text',
  labelText,
  placeholder,
  defaultValue,
  errorText,
  helpText,
  disabled,
  placeholderRight,
  value,
  readOnly,
  maxLength,
  alignEnd,
  noTranslateHelpText,
  icon,
  iconOnClick,
  onClick,
  setValue,
  ...rest
}) => {
  const { register } = useFormContext();
  const getHelpOrErrorText = (errorText?: string, helpText?: string) => {
    if (errorText) {
      return <ErrorText data-testid="errorMessage">{__(errorText)}</ErrorText>;
    }
    return helpText ? (
      <HelpText alignEnd={alignEnd}>{noTranslateHelpText ? helpText : __(helpText)}</HelpText>
    ) : null;
  };

  return (
    <InputWrapper>
      {icon && (
        <InputIcon>
          <Icon icon={icon} onClick={iconOnClick} />
        </InputIcon>
      )}
      <InputComponent
        {...register(name, {
          onChange: (e) => {
            setValue && setValue(e.target.value);
          },
        })}
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        id={name}
        readOnly={readOnly}
        value={value}
        placeholder={placeholder && __(placeholder)}
        placeholderRight={placeholderRight}
        error={errorText ? true : false}
        onClick={onClick}
        maxLength={maxLength}
        data-testid={name}
        {...rest}
      />
      {labelText && (
        <InputLabel htmlFor={name} error={errorText ? true : false}>
          {__(labelText)}
        </InputLabel>
      )}
      {getHelpOrErrorText(errorText, helpText)}
    </InputWrapper>
  );
};
