import { FC } from 'react';
import { Heading, Text } from 'components/common';
import { TextBox } from './styles';

interface Props {
  title: string;
  description?: string;
  marginBottom?: string;
}

export const HeadingWithTextBox: FC<Props> = ({ title, description, ...props }) => (
  <TextBox {...props}>
    <Heading text={title} />
    <Text text={description ? description : '---'} noTranslate />
  </TextBox>
);
