import { FC, ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './basicStyleValues';
import { GlobalStyle } from './globalStyle';

interface Props {
  children: ReactElement;
}

export const Theme: FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalStyle />
  </ThemeProvider>
);

interface MockThemeProps {
  children: React.ReactNode;
}

export const MockTheme = ({ children }: MockThemeProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
