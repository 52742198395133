import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridComponent } from 'components/common/Grid/GridContainer/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { EditSkillDictionary } from 'types';
import { SkillDiminishedData } from 'work-planner-backend/types';
import { editSkillDictionarySchema } from 'services/formValidator/schemas';
import { useModal } from 'hooks';
import { Button, Form, GridItem, Input } from 'components/common';
import { ButtonContainer } from './styles';

interface SkillDictionaryEditData {
  name: string;
  id: string;
}

export interface SkillDictionaryEditProps {
  data?: SkillDictionaryEditData;
  callback?: (id: string, newValues: SkillDiminishedData) => void;
}

export const SkillDictionaryEdit: FC<SkillDictionaryEditProps> = ({ callback, data }) => {
  const { hideModal } = useModal();

  const methods = useForm<EditSkillDictionary>({
    mode: 'onSubmit',
    resolver: yupResolver(editSkillDictionarySchema),
  });

  if (!callback || !data) {
    hideModal();
    return <></>;
  }

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    hideModal();
  };

  const handleUpdate = (dataSubmit: EditSkillDictionary) => {
    callback(data.id, dataSubmit);
    hideModal();
  };

  useEffect(() => {
    if (data) {
      const { name } = data;
      setValue('name', name);
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleUpdate)}>
        <GridComponent size={[1, 1]}>
          <GridItem position={[1, 1]}>
            <Input labelText="label.name" name="name" errorText={errors?.name?.message} />
          </GridItem>
        </GridComponent>
        <ButtonContainer center>
          <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
          <Button text="button.saveChanges" variant="primary" type="submit" />
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};
