import { Select } from 'components/common';
import { Dispatch, FC, SetStateAction } from 'react';
import { BasePagination, SelectType } from 'types';

const limitOptions: SelectType[] = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '15', value: '15' },
];

interface LimitPaginationProps {
  pagination: BasePagination;
  setPagination: Dispatch<SetStateAction<BasePagination>>;
}

export const LimitPagination: FC<LimitPaginationProps> = ({ pagination, setPagination }) => {
  const { limit } = pagination;

  const handleOnChange = (option: SelectType) => {
    setPagination({ ...pagination, limit: option.value });
  };

  return (
    <Select
      labelText="label.limit"
      name="limit"
      options={limitOptions}
      setItem={(opt) => handleOnChange(opt)}
      defaultValue={limit ? { label: limit, value: limit } : undefined}
    />
  );
};
