import { FC } from 'react';
import { projectLinkTableSchema } from 'services/formValidator/schemas/projectTable';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Input } from 'components/common';
import { useModal } from 'hooks';
import { TableItemType } from 'types';
import { ButtonContainer, EditLinkForm, FieldsContainer } from './styles';

export interface UpdateLinkTableProps {
  data?: TableItemType;
  callback?: (item: TableItemType) => void;
}

export const UpdateLinkTable: FC<UpdateLinkTableProps> = ({ data, callback }) => {
  const { hideModal } = useModal();

  const methods = useForm<TableItemType>({
    mode: 'onSubmit',
    resolver: yupResolver(projectLinkTableSchema),
    defaultValues: {
      firstColumn: data?.firstColumn,
      secondColumn: data?.secondColumn,
    },
  });

  if (!data || !callback) {
    hideModal();
    return <></>;
  }

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    hideModal();
  };

  const handleUpdate = (dataSubmit: TableItemType) => {
    callback(dataSubmit);
    hideModal();
  };

  return (
    <FormProvider {...methods}>
      <EditLinkForm onSubmit={handleSubmit(handleUpdate)}>
        <FieldsContainer>
          <Input
            name="firstColumn"
            placeholder="placeholder.title"
            defaultValue={data && data.firstColumn}
            errorText={errors.firstColumn?.message}
          />
          <Input
            name="secondColumn"
            placeholder="placeholder.pasteLink"
            defaultValue={data && data.secondColumn}
            errorText={errors.secondColumn?.message}
          />
        </FieldsContainer>
        <ButtonContainer>
          <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
          <Button text="button.update" variant="primary" onClick={() => handleUpdate} />
        </ButtonContainer>
      </EditLinkForm>
    </FormProvider>
  );
};
