import styled from 'styled-components';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  active: boolean;
  actual?: boolean;
}

const ProgressBarBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme: { spaces } }) => spaces.lg};
  margin-bottom: ${({ theme: { spaces } }) => spaces.xxxl};
`;

const ProgressStep = styled.div<Props>`
  display: flex;
  justify-content: center;
  height: ${({ theme: { sizes } }) => sizes.progressItem};
  width: ${({ theme: { sizes } }) => sizes.progressItem};
  background-color: ${({ theme: { colors }, actual }) => (actual ? colors.success : colors.border)};
  border: 2px solid ${({ theme: { colors }, active }) => (active ? colors.success : colors.border)};
  box-shadow: 0px 0px 3px 0px
    ${({ theme: { colors }, active }) => (active ? colors.success : 'transparent')};
  border-radius: ${({ theme }) => theme.radius.oval};
  cursor: ${({ actual }) => (actual ? 'pointer' : 'default')};

  ${ltMedia('sm')} {
    height: ${({ theme: { sizes } }) => `calc(${sizes.progressItem} * 0.6)`};
    width: ${({ theme: { sizes } }) => `calc(${sizes.progressItem} * 0.6)`};
  }
`;

const ProgressBarLine = styled.div<Props>`
  position: relative;
  height: 1px;
  width: ${({ theme: { sizes } }) => sizes.progressBar};
  transform: translateY(200%);
  border-bottom: 2px solid
    ${({ theme: { colors }, active }) => (active ? colors.success : colors.border)};
  box-shadow: 0px 0px 3px 0px
    ${({ theme: { colors }, active }) => (active ? colors.success : 'transparent')};

  ${ltMedia('sm')} {
    transform: translateY(120%);
  }
`;

const ProgressHelpText = styled.p`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: ${({ theme: { spaces } }) => spaces.sm};
  color: ${({ theme: { colors } }) => colors.icon};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};

  ${ltMedia('sm')} {
    font-size: ${({ theme: { fontSizes } }) => `calc(${fontSizes.xs} * (2/3))`};
  }
`;

export { ProgressStep, ProgressBarBox, ProgressBarLine, ProgressHelpText };
