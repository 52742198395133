import { ButtonForIcon } from 'components/common/Button/IconButton/styles';
import { Icon } from 'components/common';
import { ButtonHTMLAttributes, FC } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: string;
  iconColor?: string;
  icon: string;
  variant?: 'primary' | 'secondary';
}

export const IconButton: FC<Props> = ({ icon, iconColor, size, ...props }) => (
  <ButtonForIcon size={size} {...props}>
    <Icon icon={icon} iconColor={iconColor} size={size} />
  </ButtonForIcon>
);
