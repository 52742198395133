import styled from 'styled-components';

interface Props {
  error?: boolean;
}

export const TextareaComponent = styled.textarea<Props>`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: ${({ theme: { spaces } }) => spaces.defaultPadding};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
  border-radius: ${({ theme: { radius } }) => radius.sm};
  border: 1px solid ${({ theme: { colors }, error }) => (error ? colors.error : colors.border)};
  height: ${({ theme: { sizes } }) => sizes.textareaHeight};
  resize: vertical;
  overflow-y: auto;

  :not(:placeholder-shown) {
    & + label {
      color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.dark)};
    }
  }

  :focus {
    background-color: ${({ theme: { colors } }) => colors.light};
    border: 1px solid ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};

    & + label {
      color: ${({ theme: { colors }, error }) => (error ? colors.error : colors.primary)};
    }
  }

  :disabled {
    ::placeholder {
      color: ${({ theme: { colors } }) => colors.border};
    }
  }
`;

export const TextareaLabel = styled.label<Props>`
  position: absolute;
  top: -8px;
  left: 14px;
  width: fit-content;
  height: ${({ theme: { sizes } }) => sizes.lg};
  overflow: hidden;
  z-index: 2;
  padding: 0px ${({ theme: { spaces } }) => spaces.xs};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  background-color: white;
`;
export const TextareaContainer = styled.div`
  position: relative;
  margin-top: 8px;
  margin-bottom: 16px;
`;
