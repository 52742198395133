import styled, { css } from 'styled-components';
import { CirclePicker } from 'react-color';

interface Props {
  color?: string;
  error?: boolean;
  isOpen?: boolean;
}

export const CirclePickerBox = styled(CirclePicker)<Props>`
  position: absolute;
  top: ${({ theme: { spaces } }) => spaces.defaultBoxPadding};
  left: 0px;
  min-width: 100%;
  background-color: ${({ theme: { colors } }) => colors.light};
  box-shadow: ${({ theme: { colors } }) => colors.boxShadow};
  border-radius: ${({ theme: { radius } }) => radius.sm};
  padding: ${({ theme: { spaces } }) => spaces.md};
`;

export const ColorPickerComponent = styled.button<Props>`
  position: relative;
  z-index: 2;
  width: 100%;
  height: ${({ theme: { sizes } }) => sizes.inputHeight};
  background-color: ${({ theme }) => theme.colors.light};
  padding: ${({ theme: { spaces } }) => spaces.defaultPadding};
  border-radius: ${({ theme }) => theme.radius.sm};
  border: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;

  ${({ theme: { colors }, isOpen, error }) =>
    isOpen &&
    css`
      background-color: ${() => colors.light};
      border: 1px solid ${() => (error ? colors.error : colors.primary)};
    `}
`;

export const ColorBox = styled.div<Props>`
  background-color: ${({ color }) => (color ? color : 'transparent')};
  border-radius: ${({ theme }) => theme.radius.sm};
  width: auto;
  height: 100%;
`;

export const ColorBoxPlaceholder = styled.div<Props>`
  text-align: left;
  color: ${({ theme }) => theme.colors.defaultFont};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const InputLabel = styled.label<Props>`
  position: relative;
  display: block;
  width: fit-content;
  height: ${({ theme: { sizes } }) => sizes.lg};
  overflow: hidden;
  z-index: 2;
  padding: 0px ${({ theme: { spaces } }) => spaces.xs};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.xs};
  transform: translate(${({ theme: { spaces } }) => spaces.desktopDisplacement});
  background-color: white;
  ${({ color, theme: { colors }, error }) =>
    color &&
    css`
       {
        color: ${() => (error ? colors.error : colors.dark)};
      }
    `}
  ${({ theme: { colors }, isOpen, error }) =>
    isOpen &&
    css`
       {
        color: ${() => (error ? colors.error : colors.primary)};
      }
    `}
`;
