import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalData } from 'types';

export type ModalState = {
  isOpened: boolean;
  header?: string;
} & ModalData;

export type ModalProps = Omit<ModalState, 'isOpened'>;

const initialState: ModalState = {
  isOpened: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<ModalProps>) => {
      state.isOpened = true;
      state.data = payload.data;
      state.modalType = payload.modalType;
      state.header = payload.header;
      state.callback = payload.callback;
    },
    hideModal: (state) => {
      state.isOpened = false;
    },
  },
});
export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
