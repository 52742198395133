import * as yup from 'yup';
import { object as yupObject } from 'yup';
import { checkEndDate, checkStartDate } from '../yupValidators';

export const holidaySchema = yupObject().shape({
  userName: yup.string(),
  startDate: checkStartDate(),
  endDate: checkEndDate(),
  comment: yup.string().notRequired(),
});
