import { FC, MouseEventHandler, ReactNode } from 'react';
import { ItemStyled } from './styles';

interface ListItemProps {
  children?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ListItem: FC<ListItemProps> = ({ children, ...props }) => {
  return <ItemStyled {...props}>{children}</ItemStyled>;
};
