import styled from 'styled-components';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  wide?: boolean;
}

export const Form = styled.form<Props>`
  width: ${({ theme: { sizes }, wide }) => (wide ? sizes.wideFormBox : sizes.defaultFormBox)};
  ${ltMedia('lg')} {
    width: auto;
  }
`;

export const UnauthorizedForm = styled.form`
  display: flex;
  flex-direction: column;
  width: ${({ theme: { sizes } }) => sizes.unauthorizedBox};
  padding: ${({ theme: { spaces } }) => spaces.defaultFormPadding};
  background-color: ${({ theme: { colors } }) => colors.light};
  border: none;
  border-radius: ${({ theme: { radius } }) => radius.xl};
  justify-content: space-between;
  z-index: 1;
  & > input:last-of-type {
    margin: ${({ theme: { spaces } }) => spaces.lgTopMargin};
  }
  ${ltMedia('sm')} {
    width: 100vw;
    margin: ${({ theme: { spaces } }) => spaces.sm};
    padding: ${({ theme: { spaces } }) => spaces.defaultMobileBoxPadding};
    max-width: ${({ theme: { sizes } }) => sizes.unauthorizedBox};
  }
`;
