import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ArchivingRes } from 'work-planner-backend/types';
import { Button, GridContainer, GridItem, DatePickerInput } from 'components/common';
import { UserArchivingFormType } from 'types';
import { archivingDate } from 'services/formValidator/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from 'hooks';
import { FormArchiving, StyledButton } from './styles';

export interface UserArchivingProps {
  data?: ArchivingRes;
  callback?: (date: Date, remove?: boolean) => void;
}

export const UserArchiving: FC<UserArchivingProps> = ({ data, callback }) => {
  const { hideModal } = useModal();
  const methods = useForm<UserArchivingFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(archivingDate),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  if (!callback) {
    hideModal();
    return <></>;
  }

  useEffect(() => {
    if (data?.archivingDate) {
      setValue('date', new Date(data.archivingDate));
    }
  }, []);

  const restore = ({ date }: UserArchivingFormType) => callback(date, true);
  const archive = ({ date }: UserArchivingFormType) => callback(date);

  return (
    <FormProvider {...methods}>
      <FormArchiving>
        <GridContainer size={[2, 2]}>
          <GridItem position={[1, 1, 2]}>
            <DatePickerInput
              name="date"
              labelText="label.date"
              placeholder="placeholder.dateFormat"
              errorText={errors?.date?.message}
            />
          </GridItem>
          <GridItem position={[1, 2]}>
            <StyledButton
              onClick={handleSubmit(restore)}
              text="button.deleteArchive"
              variant="secondary"
            />
          </GridItem>
          <GridItem position={[2, 2]}>
            <Button onClick={handleSubmit(archive)} text="button.archive" variant="primaryFixed" />
          </GridItem>
        </GridContainer>
      </FormArchiving>
    </FormProvider>
  );
};
