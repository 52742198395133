import {
  BasePaginationRes,
  PmDiminishedData,
  Role,
  SkillFullData,
  UserPositionRes,
} from 'work-planner-backend/types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'config/store';
import { SelectType } from 'types';
import { useApi } from '../useApi';

interface UseSelectOptions {
  loadPms: () => void;
  getPmsDefaultValue: () => SelectType | undefined;
  pmsOptions: SelectType[];
  loadSkills: () => void;
  skillsOptions: SelectType[];
  loadPositionsAll: () => void;
  positionsAllOptions: SelectType[];
  loadPositionsForRole: (role: Role) => void;
  positionsForRoleOptions: SelectType[];
}

export const useSelectOptions = (): UseSelectOptions => {
  const [pmsOptions, setPmsOptions] = useState<SelectType[]>([]);
  const [skillsOptions, setSkillsOptions] = useState<SelectType[]>([]);
  const [positionsAllOptions, setPositionsAllOptions] = useState<SelectType[]>([]);
  const [positionsForRoleOptions, setPositionsForRoleOptions] = useState<SelectType[]>([]);
  const user = useSelector((state: RootState) => state.user?.userData);
  const { apiGet } = useApi();

  const loadPms = async () => {
    const result = await apiGet<BasePaginationRes<PmDiminishedData>>({
      url: 'projects/reservations/pms',
    });

    if (result) {
      const preparedPMs: SelectType[] = result.items.map((item) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      }));
      setPmsOptions(preparedPMs);
    }
  };

  const getPmsDefaultValue = () => {
    if (user) {
      const { id, role, firstName, lastName } = user;
      return role === Role.Admin ? { label: `${firstName} ${lastName}`, value: id } : undefined;
    }
    return undefined;
  };

  const loadSkills = async () => {
    const result = await apiGet<BasePaginationRes<SkillFullData>>({
      url: 'skills',
    });

    if (result) {
      const preparedSkills: SelectType[] = result.items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSkillsOptions(preparedSkills);
    }
  };

  const loadPositionsAll = async () => {
    const result = await apiGet<BasePaginationRes<UserPositionRes>>({
      url: '/users/positions/all',
    });

    if (result) {
      const preparedPositions: SelectType[] = result.items.map(({ id, positionName }) => ({
        value: id,
        label: positionName,
      }));
      setPositionsAllOptions(preparedPositions);
    }
  };

  const loadPositionsForRole = async (role: Role) => {
    if (role === Role.Admin) {
      setPositionsForRoleOptions([{ label: 'PM', value: '' }]);
      return;
    }

    const result = await apiGet<UserPositionRes[]>({
      url: `users/positions/for/${role}`,
    });

    if (result) {
      const preparedPositions: SelectType[] = result.map((item) => ({
        value: item.id,
        label: item.positionName,
      }));
      setPositionsForRoleOptions(preparedPositions);
    }
  };

  return {
    loadPms,
    pmsOptions,
    getPmsDefaultValue,
    loadSkills,
    skillsOptions,
    loadPositionsAll,
    positionsAllOptions,
    loadPositionsForRole,
    positionsForRoleOptions,
  };
};
