import * as yup from 'yup';
import { object as yupObject } from 'yup';
import { checkEmail, checkPhoneNumber } from '../yupValidators';

export const contactPersonSchema = yupObject().shape({
  firstName: yup.string().required('validation.fieldIsRequired'),
  lastName: yup.string().required('validation.fieldIsRequired'),
  email: checkEmail(),
  phoneNumber: checkPhoneNumber(),
  role: yup.string().required('validation.fieldIsRequired'),
});
