import { FC } from 'react';
import { projectLinkTableSchema } from 'services/formValidator/schemas/projectTable';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Input } from 'components/common';
import { useModal } from 'hooks';
import { TableItemType } from 'types';
import { AddLinkForm, ButtonContainer, FieldsContainer } from './styles';

export interface AddLinkTableProps {
  data?: TableItemType;
  callback?: (item: TableItemType) => void;
}

export const AddLinkTable: FC<AddLinkTableProps> = ({ data, callback }) => {
  const { hideModal } = useModal();

  const methods = useForm<TableItemType>({
    mode: 'onSubmit',
    resolver: yupResolver(projectLinkTableSchema),
    defaultValues: {
      firstColumn: '',
      secondColumn: '',
    },
  });

  if (!callback) {
    hideModal();
    return <></>;
  }

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleAdd = (dataSubmit: TableItemType) => {
    callback(dataSubmit);
    hideModal();
  };

  return (
    <FormProvider {...methods}>
      <AddLinkForm>
        <FieldsContainer>
          <Input
            name="firstColumn"
            placeholder="placeholder.title"
            defaultValue={data && data.firstColumn}
            errorText={errors.firstColumn?.message}
          />
          <Input
            icon="link"
            name="secondColumn"
            placeholder="placeholder.pasteLink"
            defaultValue={data && data.secondColumn}
            errorText={errors.secondColumn?.message}
          />
        </FieldsContainer>
        <ButtonContainer>
          <Button text="button.add" variant="primary" onClick={handleSubmit(handleAdd)} />
        </ButtonContainer>
      </AddLinkForm>
    </FormProvider>
  );
};
