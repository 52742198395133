import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { __ } from '../translation';
import { CreateMessage } from './CreateMessage';

export const errorNotification = (message: string, translation = true) => {
  toast.error(
    <CreateMessage
      heading="notification.errorMessage"
      message={message}
      translation={translation}
    />,
    {
      theme: 'colored',
    },
  );
};

export const successNotification = (message: string, translation = true) => {
  toast.success(
    <CreateMessage
      heading="notification.successMessage"
      message={message}
      translation={translation}
    />,
    {
      theme: 'colored',
    },
  );
};

export const infoNotification = (message: string, translation = true) => {
  toast.info(
    <CreateMessage
      heading="notification.infoMessage"
      message={message}
      translation={translation}
    />,
    {
      theme: 'colored',
    },
  );
};

export const warnNotification = (message: string, translation = true) => {
  toast.warn(
    <CreateMessage
      heading="notification.warnMessage"
      message={message}
      translation={translation}
    />,
    {
      theme: 'colored',
    },
  );
};
