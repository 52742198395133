import { FC } from 'react';
import { Button, Text } from 'components/common';
import { useModal } from 'hooks';
import { ButtonContainer, MainContainer } from './styles';

interface ConfirmData {
  message: string;
  approveMessage?: string;
}

export interface ConfirmProps {
  data?: ConfirmData;
  callback?: () => void;
}

export const Confirm: FC<ConfirmProps> = ({ callback, data }) => {
  const { hideModal } = useModal();

  if (!data || !callback) {
    hideModal();
    return <></>;
  }

  const handleCancel = () => {
    hideModal();
  };

  const handleApprove = () => {
    callback();
    hideModal();
  };

  const { message, approveMessage } = data;

  return (
    <MainContainer>
      <Text text={message} size="md" />
      <ButtonContainer>
        <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
        <Button text={approveMessage ?? 'button.yes'} variant="primary" onClick={handleApprove} />
      </ButtonContainer>
    </MainContainer>
  );
};
