import styled from 'styled-components';
import { ltMedia } from 'config/theme/mediaQuery';

interface Props {
  position?: string;
  margin?: string;
}

export const BasicLink = styled.div<Props>`
  margin: ${({ theme, margin }) => (margin ? theme.spaces[margin] : '0px')};
  text-align: ${({ position }) => (position ? `${position}` : 'center')};
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme: { spaces } }) => spaces.sm};
    color: ${({ theme: { colors } }) => colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    ${ltMedia('sm')} {
      font-size: ${({ theme }) => theme.fontSizes.xs};
    }
  }
`;
