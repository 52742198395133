import * as yup from 'yup';
import { object as yupObject } from 'yup';

export const addPositionDictionarySchema = yupObject().shape({
  positionName: yup.string().required('validation.fieldIsRequired'),
  forRole: yup.object().required('validation.fieldIsRequired'),
});

export const editPositionDictionarySchema = yupObject().shape({
  positionName: yup.string().required('validation.fieldIsRequired'),
  forRole: yup.object().required('validation.fieldIsRequired'),
});

export const addSkillDictionarySchema = yupObject().shape({
  name: yup.string().required('validation.fieldIsRequired'),
});

export const editSkillDictionarySchema = yupObject().shape({
  name: yup.string().required('validation.fieldIsRequired'),
});
