import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridComponent } from 'components/common/Grid/GridContainer/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { SkillType } from 'types';
import { AddEmployeeSkill } from 'work-planner-backend/types';
import { useModal, useSelectOptions } from 'hooks';
import { Button, Form, GridItem, Select } from 'components/common';
import { skillSchema } from 'services/formValidator/schemas/skill';
import { experienceOptions } from 'mocks/select';
import { ButtonContainer } from './styles';

interface SkillEditData {
  skills: AddEmployeeSkill[];
  item: AddEmployeeSkill;
}

export interface SkillEditProps {
  data?: SkillEditData;
  callback?: (item: SkillType) => void;
}

export const SkillEdit: FC<SkillEditProps> = ({ callback, data }) => {
  const { skillsOptions, loadSkills } = useSelectOptions();
  const { hideModal } = useModal();

  const methods = useForm<SkillType>({
    mode: 'onSubmit',
    resolver: yupResolver(skillSchema),
  });

  if (!callback || !data) {
    hideModal();
    return <></>;
  }

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    hideModal();
  };

  const handleUpdate = (dataSubmit: SkillType) => {
    const { level, skill } = dataSubmit;
    const preparedData = {
      level,
      skill,
    };
    callback(preparedData);
    hideModal();
  };

  const filteredSkillOptions = () =>
    skillsOptions.filter((option) => !data.skills.find((item) => option.value === item.skill.id));

  useEffect(() => {
    loadSkills();
  }, []);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleUpdate)}>
        <GridComponent size={[2, 1]}>
          <GridItem>
            <Select
              name="skill"
              labelText="label.competence"
              options={filteredSkillOptions()}
              defaultValue={{ label: data.item.skill.name, value: data.item.skill.id }}
              errorText={errors.skill?.message}
            />
          </GridItem>
          <GridItem>
            <Select
              name="level"
              labelText="label.experience"
              options={experienceOptions}
              defaultValue={{ label: String(data.item.level), value: String(data.item.level) }}
              errorText={errors.level?.message}
            />
          </GridItem>
        </GridComponent>
        <ButtonContainer center>
          <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
          <Button text="button.saveChanges" variant="primary" onClick={() => handleUpdate} />
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};
