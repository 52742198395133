import { ModalProps } from 'slices/modalSlice';
import { useAppDispatch } from 'hooks';
import { showModal as showModalAction, hideModal as hideModalAction } from 'slices/modalSlice';

interface useModalReturn {
  showModal: (args: ModalProps) => void;
  hideModal: () => void;
}

export const useModal = (): useModalReturn => {
  const dispatch = useAppDispatch();
  const showModal = (args: ModalProps) => dispatch(showModalAction(args));
  const hideModal = () => dispatch(hideModalAction());

  return {
    showModal,
    hideModal,
  };
};
