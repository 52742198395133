import styled from 'styled-components';

//Stare style - do poprawy lub usunięcia gdy będzie tworzony ten komponent w nowym widoku
const StyledButton = styled.button`
  font-size: 17px;
  color: ${(props) => props.theme.colors.main1};
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin: 0px 20px;
  font-weight: bold;
  cursor: pointer;
`;

const StyledIcon = styled.img`
  width: 18px;
  margin: 0px 10px 0px 0px;
`;

export { StyledButton, StyledIcon };
