import { useApi } from 'hooks';
import { redirectToLogin, routes } from 'router';
import { useNavigate } from 'react-router-dom';
import { logout as logoutAction } from 'slices/userSlice/actions';
import { Role, ActivationAccount } from 'work-planner-backend/types';
import { ActivateUser } from 'types';
import { useAppDispatch } from '../store';

interface UseUserReturn {
  logout: () => void;
  getRedirectLink: (role: Role) => string;
  activateUser: (body: ActivateUser) => Promise<void>;
}

export const useUser = (): UseUserReturn => {
  const { apiPost } = useApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getRedirectLink = (role: Role) => {
    switch (role) {
      case Role.SuperAdmin:
        return routes.dashboard;
      case Role.Admin:
        return routes.dashboard;
      case Role.User:
      default:
        return routes.dashboard;
    }
  };

  const logout = async () => {
    await dispatch(logoutAction());
    redirectToLogin();
  };

  const activateUser = async (body: ActivateUser) => {
    const { password, userId, activationToken } = body;

    const preparedBody: ActivationAccount = {
      password,
      activationToken,
    };

    await apiPost({
      url: `users/${userId}/activation`,
      body: preparedBody,
      callbacks: {
        successCallback: () => navigate(routes.login),
        successMessage: 'notification.activatedUser',
      },
    });
  };

  return {
    getRedirectLink,
    logout,
    activateUser,
  };
};
