import { FC } from 'react';
import { contactPersonSchema } from 'services/formValidator/schemas/contactPerson';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Form, GridItem, Input } from 'components/common';
import { GridComponent } from 'components/common/Grid/GridContainer/styles';
import { useModal } from 'hooks';
import { CreateAgent } from 'work-planner-backend/types';
import { ButtonContainer } from './styles';

export interface AddContactPersonProps {
  data?: CreateAgent;
  callback?: (item: CreateAgent) => void;
}

export const AddContactPerson: FC<AddContactPersonProps> = ({ data, callback }) => {
  const { hideModal } = useModal();

  const methods = useForm<CreateAgent>({
    mode: 'onSubmit',
    resolver: yupResolver(contactPersonSchema),
  });

  if (!callback) {
    hideModal();
    return <></>;
  }

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    hideModal();
  };

  const handleUpdate = (dataSubmit: CreateAgent) => {
    callback(dataSubmit);
    hideModal();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleUpdate)}>
        <GridComponent size={[2, 3]}>
          <GridItem>
            <Input
              name="firstName"
              labelText="label.firstName"
              defaultValue={data && data.firstName}
              errorText={errors.firstName?.message}
            />
          </GridItem>
          <GridItem>
            <Input
              name="lastName"
              labelText="label.lastName"
              defaultValue={data && data.lastName}
              errorText={errors.lastName?.message}
            />
          </GridItem>
          <GridItem>
            <Input
              name="email"
              labelText="label.email"
              defaultValue={data && data.email}
              errorText={errors.email?.message}
            />
          </GridItem>
          <GridItem>
            <Input
              name="phoneNumber"
              labelText="label.phone"
              defaultValue={data && data.phoneNumber}
              errorText={errors.phoneNumber?.message}
            />
          </GridItem>
          <GridItem>
            <Input
              name="role"
              labelText="label.role"
              defaultValue={data && data.role}
              errorText={errors.role?.message}
            />
          </GridItem>
        </GridComponent>
        <ButtonContainer>
          <Button text="button.cancel" variant="secondary" onClick={handleCancel} />
          <Button text="button.save" variant="primary" onClick={() => handleUpdate} />
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};
