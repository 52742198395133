import { ReactSVG } from 'react-svg';
import { IconContainer } from './styles';

interface Props {
  size?: string;
  icon: string;
  iconColor?: string;
  onClick?: () => void;
}

export const Icon = ({ icon, iconColor, onClick, size }: Props) => (
  <IconContainer iconColor={iconColor} onClick={onClick} isCursor={Boolean(onClick)} size={size}>
    <ReactSVG src={`/icons/${icon}.svg`} />
  </IconContainer>
);
