export interface BasePagination {
  limit?: number;
  page?: number;
  sort?: Sort[]
}

export interface Sort {
  sortBy: string;
  sortDesc: Order;
}

export interface BasePaginationWithSearch extends BasePagination {
  search?: string;
}

export interface BasePaginationRes<T> {
  items: T[];
  total: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}
