import { UserFullData } from './users';

export enum BillingPeriodType {
  PerHour = 'PER_HOUR',
  PerMonth = 'PER_MONTH',
}

export interface BillingPeriodBaseData {
  id: string;
  type: BillingPeriodType;
  startDate: Date | string;
  endDate: Date | string | null;
  maxHoursPerDay: number;
  salaryPerMonth: number;
  salaryPerHour: number;
  minimumHoursPerMonth: number;
  maximumHoursPerMonth: number;
}

export interface BillingPeriodsFullData extends BillingPeriodBaseData {
  employee: UserFullData;
}

export type AddOrEditBillingPeriod = {
  type: BillingPeriodType;
  perMonth?: AddOrEditPerMonthBillingPeriod;
  perHour?: AddOrEditPerHourBillingPeriod;
};

export type AddOrEditPerHourBillingPeriod = {
  startDate: Date | string;
  endDate: Date | null | string;
  maxHoursPerDay: number;
  salaryPerHour: number;
  minimumHoursPerMonth: number;
  maximumHoursPerMonth: number;
};

export type AddOrEditPerMonthBillingPeriod = {
  startDate: Date | string;
  endDate: Date | null | string;
  maxHoursPerDay: number;
  salaryPerMonth: number;
};

export type BillingPeriodsPerMonthRes = Omit<
  BillingPeriodBaseData,
  'salaryPerHour' | 'minimumHoursPerMonth' | 'maximumHoursPerMonth'
>;

export type BillingPeriodsPerHourRes = Omit<BillingPeriodBaseData, 'salaryPerMonth'>;

export interface BillingPeriodsPositionListRes {
  items: (BillingPeriodsPerHourRes | BillingPeriodsPerMonthRes)[];
  total: number;
}
