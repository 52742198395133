import styled from 'styled-components';

interface Props {
  iconColor?: string;
  isCursor?: boolean;
  size?: string;
}

const IconContainer = styled.div<Props>`
  > div > div {
    cursor: ${({ isCursor }) => (isCursor ? 'pointer' : 'default')};
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: ${({ size }) => size};
      height: ${({ size }) => size};
    }

    path {
      fill: ${({ theme, iconColor }) => (iconColor ? theme.colors[iconColor] : theme.colors.icon)};

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export { IconContainer };
