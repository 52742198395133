import { FC } from 'react';
import { HeadingType } from 'types';
import { TranslateText } from 'components/common';
import { BasicHeading, Count } from './styles';

interface Props {
  text: string;
  noTranslate?: boolean;
  margin?: string;
  mobileMargin?: string;
  count?: number;
  variant?: HeadingType;
  className?: string;
}

export const Heading: FC<Props> = ({
  text,
  noTranslate,
  margin,
  mobileMargin,
  count,
  variant,
  className,
}) => {
  const getQuantityOfItems = (count: number) => (
    <Count>
      <p>{count}</p>
    </Count>
  );
  return (
    <BasicHeading
      mobileMargin={mobileMargin}
      margin={margin}
      variant={variant}
      className={className}
    >
      <>
        <TranslateText text={text} noTranslate={noTranslate} />
        {count ? getQuantityOfItems(count) : null}
      </>
    </BasicHeading>
  );
};
