import { UpdateLinkTable } from 'components/modals/LinkTableEditModal';
import { AddContactPerson } from 'components/modals/AddContactPerson';
import { SkillEdit } from 'components/modals/SkillEditModal';
import { AddLinkTable } from 'components/modals/LinkTableAddModal';
import { UserAvailable } from 'components/modals/UserAvailableModal';
import { UserArchiving } from 'components/modals/UserArchiving';
import { PositionDictionaryEdit } from 'components/modals/DictionaryModal';
import { SkillDictionaryEdit } from 'components/modals/DictionaryModal/SkillEdit';
import { useAppSelector, useModal } from 'hooks';
import { ModalType } from 'types';
import { Confirm } from '../../Confirm';
import { Event } from '../../EventModal';

export const Content = () => {
  const { hideModal } = useModal();
  const { data, modalType, callback } = useAppSelector((state) => state.modal);

  switch (modalType) {
    case ModalType.CONFIRM:
      return <Confirm data={data} callback={callback} />;
    case ModalType.EVENT:
      return <Event data={data} callback={callback} />;
    case ModalType.UPDATE_LINK:
      return <UpdateLinkTable data={data} callback={callback} />;
    case ModalType.ADD_LINK:
      return <AddLinkTable data={data} callback={callback} />;
    case ModalType.EDIT_SKILL:
      return <SkillEdit data={data} callback={callback} />;
    case ModalType.ADD_CONTACT_PERSON:
      return <AddContactPerson data={data} callback={callback} />;
    case ModalType.USER_AVAILABLE:
      return <UserAvailable data={data} callback={callback} />;
    case ModalType.USER_ARCHIVING:
      return <UserArchiving data={data} callback={callback} />;
    case ModalType.EDIT_DICTIONARY_POSITION:
      return <PositionDictionaryEdit data={data} callback={callback} />;
    case ModalType.EDIT_DICTIONARY_SKILL:
      return <SkillDictionaryEdit data={data} callback={callback} />;
    default:
      hideModal();
      return <></>;
  }
};
