import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { __ } from 'services/translation';
import { RadioInputComponent, RadioLabel, RadioGroupContainer, RadioItemContainer } from './styles';

export type RadioType = {
  name: string;
  value: string;
  label: string;
};

interface Props {
  inColumnDirection?: boolean;
  prevSelectedValue?: string;
  radioGroup: RadioType[];
}

export const RadioButton: FC<Props> = ({ radioGroup, prevSelectedValue, inColumnDirection }) => {
  const { register } = useFormContext();

  return (
    <RadioGroupContainer columnDirection={inColumnDirection}>
      {radioGroup.map((item) => (
        <RadioItemContainer key={item.value}>
          <RadioInputComponent
            {...register(item.name)}
            type="radio"
            name={item.name}
            value={item.value}
            defaultChecked={
              prevSelectedValue
                ? item.value === prevSelectedValue
                : item.value === radioGroup[0].value
            }
          />
          <RadioLabel key={item.value} htmlFor={item.name}>
            {__(item.label)}
          </RadioLabel>
        </RadioItemContainer>
      ))}
    </RadioGroupContainer>
  );
};
