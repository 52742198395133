import {
  BasePaginationRes,
  UserInvolvementDetails,
  UsersInvolvement,
} from 'work-planner-backend/types';
import { useContext, useState } from 'react';
import { UserReservationsInfoListFilters } from 'types';
import { prepareURLParamsString, filterEmptyParams, prepareApiParamsObj } from 'utils/queryParams';
import { __ } from 'services/translation';
import {
  preparedApiDataFormat,
  setDefaultLastDateIfNotChosen,
  setDefaultStartDateIfNotChosen,
} from 'utils';
import { BasePaginationContext, UserReservationsListFiltersContext } from 'context';
import { useApi } from '../useApi';

interface UseUserListReservations {
  getUserReservationsInfoList: () => Promise<void>;
  applyUsersFilters: (usersFilters?: UserReservationsInfoListFilters) => Promise<void>;
  getUserReservation: (userId: string) => Promise<void>;
  userReservations: UserInvolvementDetails | undefined;
}

export const useUserListReservations = (): UseUserListReservations => {
  const { apiGet } = useApi();
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const {
    setData: setUsersBasicInfo,
    setFilters,
    filters,
  } = useContext(UserReservationsListFiltersContext);
  const [userReservations, setUserReservations] = useState<UserInvolvementDetails>();

  const getUserReservationsInfoList = async () => {
    const queryParams = prepareURLParamsString({ ...filters, ...pagination }, { encode: false });

    const result = await apiGet<BasePaginationRes<UsersInvolvement>>({
      url: `/users/involvement/list${queryParams}`,
    });

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setUsersBasicInfo(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const applyUsersFilters = async (usersFilters?: UserReservationsInfoListFilters) => {
    const preparedSkills = usersFilters?.skills?.map(({ skillId, status, max, min }) => ({
      max: max.value,
      min: min.value,
      status: status.value,
      skillId: skillId.value,
    }));

    const preparedFiltersData = {
      endDate: preparedApiDataFormat(usersFilters?.endDate),
      startDate: preparedApiDataFormat(usersFilters?.startDate),
      experienceLevel: usersFilters?.experienceLevel?.value,
      hoursMax: usersFilters?.hoursMax,
      hoursMin: usersFilters?.hoursMin,
      positionId: usersFilters?.positionId?.value,
      search: usersFilters?.search,
      skills: preparedSkills,
    };

    const queryParams = prepareURLParamsString(preparedFiltersData, { encode: false });

    const result = await apiGet<BasePaginationRes<UsersInvolvement>>({
      url: `/users/involvement/list${queryParams}`,
    });

    setFilters(filterEmptyParams(preparedFiltersData));

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setUsersBasicInfo(items);
      setPagesPossibility({ hasNextPage, hasPreviousPage });
    }
  };

  const getUserReservation = async (userId: string) => {
    const preparedDates = {
      endDate: setDefaultLastDateIfNotChosen(filters.endDate),
      startDate: setDefaultStartDateIfNotChosen(filters.startDate),
    };

    const result = await apiGet<UserInvolvementDetails>({
      url: `/users/involvement/details/${userId}`,
      options: {
        params: prepareApiParamsObj(preparedDates),
      },
    });

    if (result) {
      setUserReservations(result);
    }
  };

  return { applyUsersFilters, getUserReservationsInfoList, getUserReservation, userReservations };
};
