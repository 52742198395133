import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const Notification = styled(ToastContainer)`
  .Toastify__toast-theme--colored {
    min-width: max-content;
    .Toastify__toast-icon {
      align-self: flex-start;
    }
    .Toastify__toast-body {
      & div h2 {
        font-weight: 500;
        font-size: ${({ theme: { fontSizes } }) => fontSizes.md};
      }
      p {
        font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
      }
    }
  }
`;
