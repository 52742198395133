import { FC, useCallback } from 'react';
import { formatDate } from 'services/date';
import { Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Input } from 'components/common';
import pl from 'date-fns/locale/pl';
import { DateWrapper } from './styles';
import { DateInputType } from 'types/components';

interface Props {
  name: string;
  placeholder: string;
  labelText: string;
  errorText?: string;
  minDate?: Date;
}

export const DatePickerInput: FC<Props> = ({
  name,
  placeholder,
  labelText,
  errorText,
  minDate,
}) => {
  registerLocale('pl', pl);

  const DateInput = useCallback(
    ({ onClick, value, placeholder, labelText, name, errorText }: DateInputType) => {
      return (
        <Input
          type={value ? 'date' : 'text'}
          name={name}
          placeholder={placeholder}
          labelText={labelText}
          readOnly
          onClick={onClick}
          value={value && formatDate(value, 'inputDate')}
          errorText={errorText}
        />
      );
    },
    [],
  );

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value } }) => (
        <DateWrapper>
          <DatePicker
            selectsStart
            name={name}
            minDate={minDate}
            selected={value}
            onChange={(date) => onChange(date)}
            startDate={value}
            calendarStartDay={1}
            placeholderText={placeholder}
            formatWeekDay={(weekDay) => weekDay.substring(0, 1).toUpperCase()}
            locale="pl"
            customInput={
              <DateInput
                name={name}
                placeholder={placeholder}
                labelText={labelText}
                errorText={errorText}
                value={value}
              />
            }
          />
        </DateWrapper>
      )}
    />
  );
};
