import styled from 'styled-components';

interface Props {
  columnDirection?: boolean;
}

const RadioInputComponent = styled.input`
  margin-right: ${({ theme: { spaces } }) => spaces.sm};
  height: ${({ theme: { sizes } }) => sizes.xl};
  width: ${({ theme: { sizes } }) => sizes.xl};
`;

const RadioLabel = styled.label`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.sm};
`;

const RadioGroupContainer = styled.div<Props>`
  display: flex;
  ${({ columnDirection }) => (columnDirection ? 'column' : 'row')};
  gap: ${({ theme: { spaces } }) => spaces.md};
`;

const RadioItemContainer = styled.div`
  display: flex;
  gap: ${({ theme: { spaces } }) => spaces.xs};
  align-items: center;
`;

export { RadioInputComponent, RadioLabel, RadioGroupContainer, RadioItemContainer };
