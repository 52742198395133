import styled from 'styled-components';

interface Props {
  center?: boolean;
}

export const ButtonContainer = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-end')};
  gap: ${({ theme }) => theme.spaces.lg};

  & button {
    margin: 0px;
    width: 30%;
  }
`;
