import { createContext, Dispatch, SetStateAction } from 'react';

type ProgressBarContextType = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  nextStep: (customPath?: string) => void;
  previousStep: (customPath?: string) => void;
};

export const ProgressBarContext = createContext<ProgressBarContextType>({
  step: 1,
  nextStep: (customPath?: string) => {},
  previousStep: (customPath?: string) => {},
  setStep: () => {},
});
