import { ReactNode } from 'react';
import { GridItemComponent } from './styles';

interface Props {
  children: ReactNode | null;
  position?: number[];
  className?: string;
  onClick?: () => void;
}

export const GridItem = ({ position, children, ...props }: Props) => (
  <GridItemComponent position={position} {...props}>{children}</GridItemComponent>
);
