export const fontSizes = {
  xxs: '1.2rem',
  xs: '1.4rem',
  sm: '1.6rem',
  md: '1.8rem',
  lg: '2.0rem',
  mLg: '2.2rem',
  xl: '2.6rem',
  xxl: '2.8rem',
};
