import { createSlice } from '@reduxjs/toolkit';
import { getUserData, login, logout, UserData } from './actions';

interface UserState {
  userData?: UserData;
}

const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.userData = payload;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
    });
    builder.addCase(getUserData.fulfilled, (state, { payload }) => {
      state.userData = payload;
    });
  },
});

export default userSlice.reducer;
