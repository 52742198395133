import { ApiError } from 'services/api/interfaces';
import { errorNotification } from 'services/notifications';

interface ErrorData {
  statusCode: number;
  message: string;
}

const sendNotification = (errMessage: string, offNotification = false) => {
  if (offNotification) {
    return;
  }

  errorNotification(errMessage, false); //todo temporary off translation, wait for BE
};

export const handleError = (err: ApiError, offNotifications: boolean) => {
  const { response } = err;

  if (!response) {
    sendNotification('any response', offNotifications);
    return;
  }

  const { statusCode, message } = response.data as ErrorData;

  if ([401, 403].includes(statusCode)) {
    sendNotification('sessionExpired', offNotifications);
    return;
  }

  sendNotification(message, offNotifications);
};
