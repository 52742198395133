import { Form, ListItem } from 'components/common';
import styled from 'styled-components';
import { GridContainer, HeadingWithTextBox } from 'components/common';

export const FormReservation = styled(Form)`
  max-width: ${({ theme }) => theme.sizes.modalMinWidth};
`;

export const FreePeriodsItem = styled(ListItem)`
  &:hover {
    cursor: pointer;
  }
`;

export const DateHeading = styled(HeadingWithTextBox)`
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.defaultFont};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: normal;
    margin: 0px;
  }
  p {
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

export const DateInListContainer = styled(GridContainer)`
  width: 100%;
  border: 1px solid ${({ theme: { colors } }) => colors.background};
  padding: ${({ theme: { spaces } }) => spaces.sm};

  &:hover: {
    cursor: pointer;
  }
`;
