import { IconButton } from 'components/common/Button/IconButton';
import { Text } from 'components/common/Text';
import { Dispatch, FC, SetStateAction } from 'react';
import { BasePagination, PagesPossibility } from 'types';
import { sizes } from 'config/theme/basicStyleValues/sizes';
import { CartsContainer, LeftArrow, RightArrow } from './styles';

interface CartsPaginationProps {
  pagination: BasePagination;
  setPagination: Dispatch<SetStateAction<BasePagination>>;
  pagesPossibility: PagesPossibility;
}

export const CartsPagination: FC<CartsPaginationProps> = ({
  pagination,
  setPagination,
  pagesPossibility,
}) => {
  const { page } = pagination;
  const { hasNextPage, hasPreviousPage } = pagesPossibility;

  const handleOnNextPage = () => {
    if (page) {
      setPagination({ ...pagination, page: `${Number(page) + 1}` });
    }
  };

  const handleOnPreviousPage = () => {
    if (page) {
      setPagination({ ...pagination, page: `${Number(page) - 1}` });
    }
  };

  return (
    <CartsContainer>
      <LeftArrow>
        <IconButton
          type="button"
          icon="arrowLeft"
          variant="secondary"
          size={sizes.iconButtonSize}
          onClick={handleOnPreviousPage}
          disabled={!hasPreviousPage}
        />
      </LeftArrow>
      <Text noTranslate text={`${page}`} />
      <RightArrow>
        <IconButton
          type="button"
          icon="arrowRight"
          variant="secondary"
          size={sizes.iconButtonSize}
          onClick={handleOnNextPage}
          disabled={!hasNextPage}
        />
      </RightArrow>
    </CartsContainer>
  );
};
