import { FC, ReactNode } from 'react';
import { ListStyled } from './styles';

interface ListProps {
  className?: string;
  gap?: string;
  children?: ReactNode;
}

export const List: FC<ListProps> = ({ children, ...props }) => (
  <ListStyled {...props}>{children}</ListStyled>
);

export * from './ListItem';
