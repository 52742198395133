import { ReactNode } from 'react';
import { GridComponent } from './styles';

interface Props {
  children: ReactNode;
  size: number[];
  verticalMargin?: string;
  className?: string;
  onClick?: () => void;
}

export const GridContainer = ({ children, size, verticalMargin, className, onClick }: Props) => (
  <GridComponent
    size={size}
    verticalMargin={verticalMargin}
    className={className}
    onClick={onClick}
  >
    {children}
  </GridComponent>
);
