import { GridContainer } from 'components/common';
import { ltMedia } from 'config/theme/mediaQuery';
import styled from 'styled-components';

interface Props {
  width?: string;
  gap?: string;
}

export const PersonalDataBar = styled(GridContainer)`
  width: 100%;
  border: 1px solid ${({ theme: { colors } }) => colors.background};
  padding: ${({ theme: { spaces } }) => spaces.sm};
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const AccordionContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.light};
  padding: ${({ theme }) => theme.spaces.defaultBoxPadding};
  border-radius: ${({ theme }) => theme.radius.sm};
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  margin: ${({ theme }) => theme.spaces.smBottomMargin};
  width: ${({ width }) => width};
  gap: ${({ gap }) => gap};

  ${ltMedia('lg')} {
    width: 100%;
  }
`;
