import styled from 'styled-components';

interface Props {
  columnDirection?: boolean;
  width?: string;
  space?: string;
  gap?: string;
  align?: string;
}

export const FlexComponent = styled.div<Props>`
  & > * {
    width: 100%;
  }
  display: flex;
  align-items: ${({ align }) => (align ? `${align}` : 'flex-start')};
  flex-direction: ${({ columnDirection }) => (columnDirection ? 'column' : 'row')};
  justify-content: ${({ space }) => (space ? `${space}` : 'flex-start')};
  width: ${({ width }) => (width ? `${width}` : '100%')};
  gap: ${({ theme: { spaces }, gap }) => (gap ? spaces[gap] : '0px')};
`;
