import { DaysOffFullData } from '../days-off';
import { AddEmployeeSkill, BasicEmployeeSkillsData } from './employee-skills';
import { UserPositionFullData } from './employee-positions';
import { ArchivingRes } from './archiving-user';
import { BasePaginationWithSearch } from '../pagination';

export enum Role {
  User = 'USER',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPERADMIN',
}

export enum ExperienceLevel {
  Intern = 'INTERN',
  Junior = 'JUNIOR',
  JuniorPlus = 'JUNIOR_PLUS',
  Mid = 'MID',
  MidPlus = 'MID_PLUS',
  Senior = 'SENIOR',
  Head = 'HEAD',
}

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Archived = 'ARCHIVED',
}

export interface UserFullData extends UserDataWithDetails {
  password: string;
  changePasswordToken: string | null;
  changePasswordTokenExp: Date | null;
  activationToken: string | null;
  activationTokenExp: Date | null;
}

export interface UserDataWithDetails extends UserDataWithArchiving {
  skills: BasicEmployeeSkillsData[];
  daysOff: DaysOffFullData[];
}

export type UserDataWithArchiving = UserBasicData & ArchivingRes;

export interface UserBasicData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  isManuallyBlocked: boolean;
  isAccountBlocked: boolean;
  isArchived: boolean;
  position: UserPositionFullData | null;
  experienceLevel: ExperienceLevel;
}

export type UserDiminishedData = Pick<UserBasicData, 'id' | 'firstName' | 'lastName' | 'position'>;

export type PmDiminishedData = Pick<UserBasicData, 'id' | 'firstName' | 'lastName'>;

export type UserNameData = {
  id: string;
  name: string;
};

export type AddUser = Omit<
  UserBasicData,
  'id' | 'isAccountBlocked' | 'isArchived' | 'isManuallyBlocked'
> & {
  userSkills: AddEmployeeSkill[];
};

export type UpdateUser = Omit<
  UserBasicData,
  'id' | 'isAccountBlocked' | 'isArchived' | 'isManuallyBlocked' | 'role'
>;

export interface EmployeePaginationWithSearch extends BasePaginationWithSearch {
  startDate?: string;
  endDate?: string;
  positionId?: string;
  experienceLevel?: ExperienceLevel;
}

export interface EmployeesPaginationWithSearchAndHoursRange extends EmployeePaginationWithSearch {
  hoursMin?: number;
  hoursMax?: number;
}

export type SkillFilter = {
  skillId?: string;
  min?: number;
  max?: number;
  status?: SkillStatus;
};

export enum SkillStatus {
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
}

export interface UserPaginationWithBaseSearch extends BasePaginationWithSearch {
  positionId?: string;
  experienceLevel?: ExperienceLevel;
}

export interface UserPaginationWithSearchAndFilterByPositionExperienceLevelAndStatus
  extends UserPaginationWithBaseSearch {
  status?: EmployeeStatus;
}

export interface BaseUserDataToList {
  id: string;
  firstName: string;
  lastName: string;
  positionName: string | null;
  experienceLevel: ExperienceLevel;
  isArchived: boolean;
  isAccountBlocked: boolean;
  isManuallyBlocked: boolean;
}

export interface AnnualInvolvementReq {
  year?: number | string;
}

export interface MonthlyInvolvementReq extends AnnualInvolvementReq {
  month?: number | string;
}

export interface WeeklyInvolvementReq {
  date?: string | Date;
}

export interface AddSuperUser {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface RestorePassword {
  email: string;
}

export interface ResetPassword {
  newPassword: string;
  changePasswordToken: string;
}

export interface ActivationAccount {
  password: string;
  activationToken: string;
}
