import styles from 'styled-components';

export const CartsContainer = styles.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const LeftArrow = styles.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const RightArrow = styles.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
