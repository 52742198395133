import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.lg};
  margin-top: ${({ theme }) => theme.spaces.lg};

  & button {
    width: 25%;
  }
`;
