import { ButtonHTMLAttributes } from 'react';
import { ButtonType } from 'types';
import { Icon, TranslateText } from 'components/common';
import { BasicButton } from '../styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  variant: ButtonType;
  icon?: string;
  iconLeft?: string;
  iconColor?: string;
  noTranslate?: boolean;
  positionLeft?: boolean;
}

export const ButtonWithIcon = ({
  text,
  variant,
  onClick,
  icon,
  iconLeft,
  iconColor,
  type,
  noTranslate,
  positionLeft,
}: Props) => (
  <BasicButton onClick={onClick} variant={variant} positionLeft={positionLeft} type={type}>
    {iconLeft && <Icon icon={iconLeft} iconColor={iconColor} />}
    <TranslateText text={text} noTranslate={noTranslate} />
    {icon && <Icon icon={icon} iconColor={iconColor} />}
  </BasicButton>
);
