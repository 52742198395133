import styled from 'styled-components';

interface Props {
  checked?: boolean;
  margin?: string;
}

const CheckboxLabel = styled.label<Props>`
  text-align: left;
  display: flex;
  align-items: center;
  margin: ${({ theme, margin }) => theme.spaces[margin ? margin : '0px']};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.defaultFont};
`;

const HiddenCheckbox = styled.input`
  clip: rect(0 0 0 0);
  position: absolute;
`;

const Checkbox = styled.div<Props>`
  border: 1px solid ${({ theme }) => theme.colors.border};
  width: ${({ theme }) => theme.sizes.xl};
  height: ${({ theme }) => theme.sizes.xl};
  margin-right: ${({ theme }) => theme.spaces.sm};
  background: ${({ theme, checked }) => (checked ? theme.colors.primary : theme.colors.light)};
  border-radius: ${({ theme }) => theme.radius.sm};
  cursor: pointer;

  & div {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export { CheckboxLabel, HiddenCheckbox, Checkbox };
