import { Button, Form } from 'components/common';
import styled from 'styled-components';

export const FormArchiving = styled(Form)`
  max-width: ${({ theme }) => theme.sizes.modalMinWidth};
`;

export const StyledButton = styled(Button)`
  margin-left: 0;
`;
